<template>
    <div id="indoorAreasPanel" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div
                class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
            >
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu
                    activeItem="indoorAreas"
                ></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div
                    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                >
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle
                                subHeaderDescKey="area_indoorAreasListSubTitle"
                            ></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet
                            ref="accessErrorPortlet"
                            v-show="!accessGranted"
                            activeItem="indoorAreas"
                            :siteId="siteId"
                        ></app-accesserrorportlet>

                        <div
                            v-if="accessGranted"
                            class="kt-content kt-grid__item kt-grid__item--fluid"
                        >
                            <!-- begin:: Content -->
                            <div class="row marginRowLeftAndRightZero">
                                <div
                                    class="col-lg-12 alert alert-light alert-elevate"
                                    role="alert"
                                >
                                    <div class="alert-icon">
                                        <i
                                            class="flaticon-questions-circular-button kt-font-brand"
                                        ></i>
                                    </div>
                                    <div class="alert-text">
                                        <span>{{ $t("area_helpText1") }}</span>
                                        <br />
                                        <span>{{ $t("area_helpText2") }}</span>
                                        <br />
                                        <span>{{ $t("area_helpText3") }}</span>
                                    </div>
                                    <div class="alert-close">
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true"
                                            ><i class="la la-close"></i
                                            ></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-5 col-xl-4">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("area_indoorAreasList") }}
                                                </h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <template
                                                    v-if="
                                                        (currentIndoorArea.state === '' ||
                                                            currentIndoorArea.state === 'HIGHLIGHT') &&
                                                            siteIndoorAreas &&
                                                            siteIndoorAreas.length !== 0 &&
                                                            !currentSite.isInProduction
                                                    "
                                                >
                                                    <a
                                                        id="createNewIndoorAreaButton"
                                                        href="#"
                                                        @click="onCreate"
                                                        class="btn btn-brand btn-bold btn-sm btn-icon-h"
                                                    >
                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                        <span id="createNewIndoorAreaLabel">{{
                                                            $t("common_create")
                                                        }}</span>
                                                    </a>
                                                </template>
                                                <button   v-if="(currentIndoorArea.state === '' || currentIndoorArea.state === 'HIGHLIGHT') && siteIndoorAreas && siteIndoorAreas.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled btn btn-brand btn-bold btn-sm btn-icon-h">
                                                    <i class="flaticon2-plus kt-margin-r-5"></i>
                                                    <span id="createNewIndoorAreaLabel">{{ $t("common_create") }}</span>
                                                </button>
                                                <template
                                                    v-if="
                                                        (currentIndoorArea.state === 'START_CREATION' ||
                                                            currentIndoorArea.state === 'FINALIZE_CREATION' ||
                                                            currentIndoorArea.state === 'START_MEASURE') &&
                                                            siteIndoorAreas.length !== 0 &&
                                                            !currentSite.isInProduction
                                                    "
                                                >
                                                    <a
                                                        id="cancelCreateNewIndoorAreaButton"
                                                        href="#"
                                                        @click="onCancelCreation"
                                                        data-toggle="kt-tooltip"
                                                        data-placement="top"
                                                        :data-original-title="$t('gtw_cancelAction')"
                                                        class="indoorAreaListLink btn btn-sm btn-outline-dark btn-bold"
                                                    >
                                                        <span id="cancelCreateIndoorAreaLabel">{{
                                                            $t("common_cancel")
                                                        }}</span>
                                                    </a>
                                                </template>
                                                <button   v-if="(currentIndoorArea.state === 'START_CREATION' || currentIndoorArea.state === 'FINALIZE_CREATION' || currentIndoorArea.state === 'START_MEASURE') && siteIndoorAreas.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled walkwayListLink btn btn-sm btn-outline-dark btn-bold">
                                                    <span id="cancelCreateIndoorAreaLabel">{{ $t("common_cancel") }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span
                                                v-if="siteIndoorAreas && siteIndoorAreas.length == 1"
                                                class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge"
                                            >{{ siteIndoorAreas.length }}
                                                {{ $t("area_indoorArea") }} &nbsp;/&nbsp;
                                                {{ totalArea }} m<sup>2</sup></span
                                                >
                                            <span
                                                v-else-if="
                                                    siteIndoorAreas && siteIndoorAreas.length > 1
                                                "
                                                class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge"
                                            >{{ siteIndoorAreas.length }}
                                                {{ $t("area_indoorAreas") }} &nbsp;/&nbsp;
                                                {{ totalArea }} m<sup>2</sup></span
                                            >

                                            <!--begin emptyList component-->
                                            <app-emptylist
                                                targetObjectName="INDOOR_AREA"
                                                mainTextKey="area_notFound"
                                                subTextKey="area_notFoundSubLabel"
                                                imgName="empty_indoorarea.svg"
                                                v-bind:isDisplayed="isEmptyList"
                                                :siteInProduction="siteInProduction"
                                                :class="{ fullWidth: true }"
                                                @oncreateindoorarea="onCreate"
                                                @oncancelcreateindoorarea="onCancelCreation"
                                            ></app-emptylist>
                                            <!--end emptyList component-->

                                            <div
                                                v-if="siteIndoorAreas && siteIndoorAreas.length !== 0"
                                                class="form-group"
                                            >
                                                <div
                                                    class="kt-input-icon kt-input-icon--left"
                                                    style="margin-top: -10px; margin-bottom: -10px;"
                                                >
                                                    <input
                                                        v-model="searchTextValue"
                                                        type="search"
                                                        class="form-control"
                                                        :placeholder="$t('area_searchBy')"
                                                        id="indoorAreas_searchInput"
                                                    />
                                                    <span
                                                        class="kt-input-icon__icon kt-input-icon__icon--left"
                                                    >
                                                        <span><i class="la la-search"></i></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active">
                                                    <div
                                                        v-if="filteredIndoorAreas.length > 0"
                                                        id="indoorAreasListWithScrollbar"
                                                        class="kt-scroll"
                                                        data-scroll="true"
                                                        data-height="600"
                                                    >
                                                        <div class="kt-widget4" id="indoorAreasList">
                                                            <!-- Items will goes there -->
                                                            <div
                                                                v-for="(indoorArea,
                                                                        index) in filteredIndoorAreas"
                                                                :key="indoorArea.id"
                                                                class="kt-widget4__item indoorAreaLine"
                                                                :class="
                                                                    index === filteredIndoorAreas.length - 1
                                                                        ? 'kt-margin-b-30'
                                                                        : ''
                                                                "
                                                            >
                                                                <div
                                                                    @click="onHighlight"
                                                                    :data-indoorareaid="indoorArea.id"
                                                                    v-bind:class="
                                                                        getHighlightListClass(indoorArea)
                                                                    "
                                                                    class="kt-widget4__info"
                                                                >
                                                                    <a
                                                                        @click="onHighlight"
                                                                        :data-indoorareaid="indoorArea.id"
                                                                        href="#"
                                                                        class="highlightIndoorAreaLocationLink kt-widget4__username"
                                                                        v-bind:class="getHighlightClass(indoorArea)"
                                                                    >
                                                                        {{ indoorArea.name }}
                                                                    </a>
                                                                    <p class="kt-widget4__text colorSubText">
                                                                        {{ indoorArea.area }}
                                                                        <span
                                                                            v-if="
                                                                                indoorArea.areaUnit === 'squareMeters'
                                                                            "
                                                                        >
                                                                            m<sup>2</sup></span
                                                                        >
                                                                        <span v-else> ft<sup>2</sup></span>
                                                                    </p>
                                                                    <p class="kt-widget4__text colorSubText">
                                                                        {{ indoorArea.building.name }} /
                                                                        {{ indoorArea.floor.name }} ({{
                                                                            $t("b&f_level")
                                                                        }}: {{ indoorArea.floor.floorNumber }})
                                                                    </p>
                                                                </div>

                                                                <template
                                                                    v-if="
                                                                        currentIndoorArea.id === indoorArea.id &&
                                                                            currentIndoorArea.state == 'EDIT'
                                                                    "
                                                                >
                                                                    <a
                                                                        @click="onCancelEditIndoorArea"
                                                                        :data-indoorareaid="indoorArea.id"
                                                                        href="#"
                                                                        data-toggle="kt-tooltip"
                                                                        data-placement="top"
                                                                        :data-original-title="
                                                                            $t('gtw_cancelAction')
                                                                        "
                                                                        class="cancelEditIndoorAreaLocationButton indoorAreaListLink btn btn-sm btn-outline-dark btn-bold"
                                                                    >
                                                                        {{ $t("common_cancel") }}
                                                                    </a>
                                                                    <a
                                                                        @click="onSaveEditIndoorArea"
                                                                        :data-indoorareaid="indoorArea.id"
                                                                        href="#"
                                                                        data-toggle="kt-tooltip"
                                                                        data-placement="top"
                                                                        :data-original-title="
                                                                            $t('area_saveEditIndoorArea')
                                                                        "
                                                                        class="saveEditIndoorAreaLocationButton btn btn-sm btn-outline-dark btn-bold"
                                                                    >
                                                                        <i class="la la-save editedAreaIcon"></i>
                                                                        {{ $t("common_save") }}
                                                                    </a>
                                                                </template>

                                                                <template
                                                                    v-if="
                                                                        (currentIndoorArea.id === '' ||
                                                                            currentIndoorArea.state ===
                                                                            'HIGHLIGHT') &&
                                                                            currentIndoorArea.state !==
                                                                            'START_CREATION' &&
                                                                            currentIndoorArea.state !==
                                                                            'START_MEASURE'
                                                                    "
                                                                >
                                                                    <a
                                                                        @click="onHighlight"
                                                                        :data-indoorareaid="indoorArea.id"
                                                                        href="#"
                                                                        data-toggle="kt-tooltip"
                                                                        data-placement="top"
                                                                        :data-original-title="
                                                                            $t('area_highlightIndoorArea')
                                                                        "
                                                                        class="highlightIndoorAreaLocationButton indoorAreaListLink btn btn-sm btn-outline-success btn-bold"
                                                                    >
                                                                        <i
                                                                            class="la la-crosshairs indoorAreaListIcon"
                                                                        ></i>
                                                                    </a>
                                                                    <div
                                                                        class="editIndoorAreaDropdown btn-group dropleft show"
                                                                        data-toggle="kt-tooltip"
                                                                        :data-original-title="$t('common_edit')"
                                                                        data-placement="top"
                                                                    >
                                                                        <a
                                                                            href="#"
                                                                            class="editIndoorAreaDropdownButton indoorAreaListLink btn btn-sm btn-outline-warning btn-bold"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="true"
                                                                        >
                                                                            <i
                                                                                class="la la-pencil indoorAreaListIcon"
                                                                            ></i>
                                                                        </a>
                                                                        <div class="dropdown-menu">
                                                                            <a
                                                                                @click="onEditIndoorAreaInfos"
                                                                                :data-indoorareaid="indoorArea.id"
                                                                                class="editIndoorAreaInfosItem dropdown-item"
                                                                                href="#"
                                                                            >{{ $t("area_editInformations") }}</a
                                                                            >
                                                                            <a
                                                                                v-if="!currentSite.isInProduction"
                                                                                @click="onEditIndoorArea"
                                                                                :data-indoorareaid="indoorArea.id"
                                                                                class="editIndoorAreaPolygonItem dropdown-item"
                                                                                href="#"
                                                                            >{{ $t("area_editPolygon") }}</a
                                                                            >
                                                                            <a v-else :data-indoorareaid="indoorArea.id" class="editIndoorAreaPolygonItem dropdown-item" href="#" data-toggle="kt-tooltip" :data-original-title="$t('common_unauthorizedAction')" data-placement="top">
                                                                                <del> {{ $t("area_editPolygon") }} </del>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        v-if="!currentSite.isInProduction"
                                                                        @click="onDeleteIndoorArea"
                                                                        :data-indoorareaid="indoorArea.id"
                                                                        :data-indoorareaname="indoorArea.name"
                                                                        href="#"
                                                                        data-toggle="kt-tooltip"
                                                                        data-placement="top"
                                                                        :data-original-title="
                                                                            $t('area_removeIndoorArea')
                                                                        "
                                                                        class="removeIndoorAreaButton btn btn-sm btn-outline-danger btn-bold"
                                                                    >
                                                                        <i
                                                                            class="la la-remove indoorAreaListIcon"
                                                                        ></i>
                                                                    </a>
                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeIndoorAreaButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove indoorAreaListIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <div class="col-lg-7 col-xl-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <!--begin: map -->
                                                <app-locationmap
                                                    mode="indoorAreasDefinition"
                                                ></app-locationmap>
                                                <!--end: map -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <!-- end:: Content -->
                            </div>
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
        <app-indoorareamodal></app-indoorareamodal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import IndoorAreaModal from "../modals/indoorareamodal.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import L from "leaflet"; // eslint-disable-line no-unused-vars
import commonVueHelper from '../../helpers/commonVueHelper';

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteIndoorAreasTable: null,
            isEmptyList: false,
            isCreation: true,
            localIndoorAreas: [],
            searchTextValue: "",
            totalArea: 0,
            isMounted: false,
            siteInProduction: false // using for emptylist element prop
        };
    },
    created: function() {
        console.log("Component(IndoorAreas)::created() - called");

        this.getSiteIndoorAreas(this.siteId);
        this.getSiteWalls(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
        this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(IndoorAreas)::mounted() - Init metronic layout");
        this.isMounted = true;
        $(window).on("resize", () => {
            this.updateScrollbar();
        });
        this.updateScrollbar();
        this.getWallTypes();
    },
    destroyed: function() {
        console.log("Component(IndoorAreas)::destroyed() - called");
        this.resetIndoorAreasState();
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteIndoorAreas(areas) {
            console.log("Component(IndoorAreas)::watch(siteIndoorAreas) - called with : ", areas);
            if (this.siteIndoorAreas) {
                this.localIndoorAreas = this.siteIndoorAreas;
                if (this.siteIndoorAreas.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                    this.calculateTotalArea();
                }
            }
        },

        buildingsAndTheirFloorsArray: function(arrayBF) {
            console.log("Component(IndoorAreas)::watch(buildingsAndTheirFloorsArray) - called with : ", arrayBF);
            this.getSiteIndoorAreas(this.siteId);
        },

        currentIndoorArea() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentIndoorArea.state === "HIGHLIGHT" && this.currentIndoorArea.isHighlightFromMap) {
                var element = $(".highlightIndoorAreaLocationLink[data-indoorareaid='" + this.currentIndoorArea.id + "']");
                $("#indoorAreasListWithScrollbar").scrollTop(0);
                $("#indoorAreasListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        },

        currentSite(site) {
            console.log("Component(IndoorAreas)::watch(currentSite)");
            if (site) {
                this.siteInProduction = site.isInProduction;
            }
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "siteIndoorAreas",
            "buildingsAndTheirFloorsArray",
            "getBuildingById",
            "getFloorByBuildingIdAndFloorId",
            "currentIndoorArea",
            "getIndoorAreaById",
            "currentSite"
        ]),

        // Computed property used to filter
        filteredIndoorAreas: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            var self = this;
            if (!self.searchTextValue) {
                return this.localIndoorAreas;
            } else {
                let filtered = this.localIndoorAreas.filter(function(indoorArea) {
                    return (
                        indoorArea.name
                            .toLowerCase()
                            .indexOf(self.searchTextValue.toLowerCase()) != -1 ||
            (indoorArea.building &&
              indoorArea.building.name
                  .toLowerCase()
                  .indexOf(self.searchTextValue.toLowerCase()) != -1) ||
            (indoorArea.floor &&
              indoorArea.floor.name
                  .toLowerCase()
                  .indexOf(self.searchTextValue.toLowerCase()) != -1)
                    );
                });
                return filtered;
            }
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "setCurrentIndoorArea",
            "getSiteIndoorAreas",
            "showDeleteModal",
            "resetIndoorAreasState",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed",
            "getSiteWalls",
            "getSiteBLEGateways",
            "getWallTypes",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences"
        ]),

        updateScrollbar() {
            let indoorAreasListScrollbar = $("#indoorAreasListWithScrollbar");
            let offset = 185;
            if (indoorAreasListScrollbar) {
                // timeout is used to be sure to get the current map height
                setTimeout(() => {
                    let mapHeight = $("#map").height();
                    indoorAreasListScrollbar.data(
                        "height",
                        mapHeight > offset * 2 ? mapHeight - offset : offset
                    );
                    indoorAreasListScrollbar.attr("data-height", $("#map").height() - offset);
                    KTApp.init();
                    KTLayout.init();
                }, 100);
            }
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(indoorArea) {
            return {
                cursorDefault: !indoorArea.polygon || !indoorArea.polygon.type,
                highlightedName: this.currentIndoorArea.id === indoorArea.id,
                colorMainText: this.currentIndoorArea.id !== indoorArea.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(indoorArea) {
            return {
                cursorPointer: indoorArea.polygon && indoorArea.polygon.type,
                highlightedItemList: this.currentIndoorArea.id === indoorArea.id
            };
        },

        onCreate() {
            // small timer is needed for autoscroll over map to work correctly
            setTimeout(() => {
                // Scroll on leaflet map
                if ($("#map").offset().top > $(window).height()) {
                    // only if map is not currently visible on screen, autoscroll
                    document.getElementById("map").scrollIntoView();
                }

                this.setCurrentIndoorArea({ id: "", state: "START_CREATION" });
            }, 1);
        },

        onCancelCreation() {
            console.log(
                "Component(IndoorAreas)::onCancelCreate - Cancel button clicked."
            );
            if (this.currentIndoorArea.state === "START_MEASURE") {
                this.setCurrentIndoorArea({ id: "", state: "CANCEL_MEASURE" });
            } else {
                this.setCurrentIndoorArea({ id: "", state: "CANCEL_CREATION" });
            }
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        onHighlight(event) {
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            console.log(
                "Component(IndoorAreas)::onHighlight - Highlight button clicked. indoorAreaId = " +
          indoorAreaId
            );
            this.setCurrentIndoorArea({ id: indoorAreaId, state: "HIGHLIGHT" });
        },

        onEditIndoorArea(event) {
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            console.log(
                "Component(IndoorAreas)::onEditIndoorArea - Edit polygon clicked. indoorAreaId = " +
          indoorAreaId
            );
            this.setCurrentIndoorArea({ id: indoorAreaId, state: "EDIT" });
        },

        onEditIndoorAreaInfos(event) {
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            console.log(
                "Component(IndoorAreas)::onEditIndoorAreaInfos - Edit infos clicked. indoorAreaId = " +
          indoorAreaId
            );

            // Get last version of indoor area
            let indoorArea = this.getIndoorAreaById(indoorAreaId);

            var indoorAreaData = {
                id: indoorArea.id,
                name: indoorArea.name,
                color: indoorArea.color,
                siteId: indoorArea.site,
                buildingId: indoorArea.building.id,
                floorId: indoorArea.floor.id,
                polygon: indoorArea.polygon,
                area: indoorArea.area,
                areaUnit: indoorArea.areaUnit,
                state: "FINALIZE_EDITION"
            };
            // Will open confirmation pop-up from indoorAreaModal.vue component
            this.setCurrentIndoorArea(indoorAreaData);
        },

        // This method is called when user clicks on "Cancel" button after edit one indoor area
        onCancelEditIndoorArea(event) {
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            console.log(
                "Component(IndoorAreas)::onCancelEditIndoorArea - indoorAreaId = " +
          indoorAreaId
            );
            this.setCurrentIndoorArea({ id: indoorAreaId, state: "CANCEL_EDIT" });
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        // This method is called when user click ton "Save" button after edit location of one gateway
        onSaveEditIndoorArea(event) {
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            console.log(
                "Component(IndoorAreas)::onSaveEditIndoorArea - indoorAreaId = " +
          indoorAreaId
            );
            this.setCurrentIndoorArea({ id: indoorAreaId, state: "SAVE_EDIT" });
        },

        onDeleteIndoorArea(event) {
            console.log("Component(IndoorAreas)::onDeleteIndoorArea - Delete button clicked. indoorAreaId = " +indoorAreaId);
            let target = event.currentTarget;
            let indoorAreaId = $(target).attr("data-indoorareaid");
            let indoorAreaName = $(target).attr("data-indoorareaname");
            this.showDeleteModal({
                textContent: i18n.t("area_deleteIndoorAreaConfirmText", {
                    indoorAreaName: indoorAreaName
                }),
                actionName: "DELETE_INDOOR_AREA",
                params: { siteId: this.siteId, indoorAreaId: indoorAreaId }
            });
        },

        calculateTotalArea() {
            console.log("Component(IndoorAreas)::calculateTotalArea - called ");
            let totalArea = 0;
            for (var i in this.siteIndoorAreas) {
                if (this.siteIndoorAreas.hasOwnProperty(i)) {
                    let indoorArea = this.siteIndoorAreas[i];
                    totalArea += indoorArea.area;
                }
            }
            this.totalArea = Math.round(totalArea * 1000) / 1000;
        },

        onNeedHelp() {
            this.$refs.indoorAreaDemoTour.startDemoTour();
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-indoorareamodal": IndoorAreaModal,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
  display: contents;
}
.padding2 {
  padding: 2px;
  z-index: 1;
}
.indoorAreaListIcon {
  margin-top: -2px;
  margin-right: -11px;
  margin-left: -6px;
}
.indoorAreaListLink {
  margin-right: 3px;
}
.alreadyLocatedLink {
  margin-right: 3px;
}
.editedAreaIcon {
  margin-top: -4px;
}
.colorMainText {
  color: #5c7cf7 !important;
}
.colorSubText {
  color: #5a6077 !important;
}
.cursorPointer {
  cursor: pointer;
}
.font500 {
  font-weight: 500;
}
.list-top-badge {
  margin-top: -10px;
}
.counterTopList {
  font-size: 12px;
  padding: 10px 10px 10px 10px;
  width: fit-content;
  background: #009688 !important;
}
.indoorAreaLine a:last-child {
  margin-right: 8px;
}

.helpButtonContainer {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.leaflet-tooltip-left:before {
  display: none;
}
</style>
