<template>
    <div id="viewDashboard" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="widgets"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" style=" padding-top: 55px; ">

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: dashboard content -->
                        <div id="viewDashboard_content" class="kt-content kt-grid__item kt-grid__item--fluid">

                            <!-- begin:: head -->
                            <div style=" display: block; margin-bottom: 10px; margin-top: -60px; ">
                                <span id="dashboardTitle" style="color: #273771;font-size: 25px;font-weight: 400;">{{ this.currentDashboard ? this.currentDashboard.name : "" }} <span style="font-size: 12px;">({{ $t("analytics_dashboardLastRefreshInfo") }} <span style="color:#4a66f3;font-weight:500;">{{displayLastRefreshInfo}}</span>)</span></span>
                                <div style=" float: right; ">
                                    <router-link :to="{ name: 'dashboards' }" class="btnCloseDash btn btn-outline-dark btn-bold btn-sm kt-margin-r-5">
                                        <i class="flaticon2-delete kt-margin-r-5"></i>
                                        {{ $t("analytics_backToDashboards") }}
                                    </router-link>
                                    <button class="btn btn-brand btn-bold btn-sm kt-margin-r-5" v-on:click="onAddWidget">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("analytics_addNewWidget") }}
                                    </button>
                                    <div class="dropdown dropdown-inline">
                                        <button type="button" class="btnMoreDash btn btn-outline-dark btn-icon btn-sm btn-icon-md" style="height: 32px;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            <i class="flaticon-more moreButtonChart"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-right" style="margin-left: 10px">
                                            <li class="dropdown-item customActiveDropdown">
                                                <a href="#" @click.self="switchDarkMode" style="justify-content: space-between;color: #a7abc3;">
                                                    <i class="fa fa-moon"></i> {{ $t("analytics_darkMode") }}
                                                    <span class="kt-switch kt-switch--sm kt-switch--icon" style="margin-left: 20px;margin-bottom: -16px;margin-top: -3px;">
                                                        <label>
                                                            <input type="checkbox" name="" v-model="darkMode" @change="onChangeDarkMode(true)">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </a>
                                            </li>
                                            <li class="dropdown-submenux dropleft dropdown-item customActiveDropdown intervalSelectDropdown">
                                                <a tabindex="-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="ri-item" style="align-items: baseline; justify-content: space-between;color: #a7abc3;">
                                                    <i style="font-weight: bold;" class="flaticon2-time"></i>
                                                    <span>{{ $t("analytics_dashboardRefreshInterval") }}</span>
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value="10" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_seconds", 10) }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value = "30" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_seconds", 30) }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value="60" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_minutes") }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value="180" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_minutes", 3) }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value="600" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_minutes", 10) }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <label class="kt-radio kt-radio--brand">
                                                            <input type="radio" value="3600" v-model="refreshInterval" @change="updateRefreshInterval">
                                                            {{ $tc("analytics_RI_hours", 1) }}
                                                            <span></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: head -->

                            <!-- begin:: widget row -->
                            <div id="viewDashboard_widgets_row" class="row">
                                <!-- begin:: draggable zones -->
                                <div class="col-md-4 draggable-zone" v-for="(arrayWidget, indexZone) in arrayWidgetsByZone" :key="indexZone" :id="'vd_widgets_zone_'+indexZone">
                                    <!-- begin:: draggable widget -->
                                    <div v-for="widget in arrayWidget" :key="widget.id" :id="widget.id ? 'vd_widget_'+widget.id : ''" :class="widget.id ? 'draggable' : 'emptyWidget'" >
                                        <app-genericwidgettile v-bind:widgetId="widget.id"></app-genericwidgettile>
                                    </div>
                                    <!-- end:: draggable widget -->
                                </div>
                                <!-- end:: draggable zones -->
                                <!-- begin:: non draggable zone -->
                                <div class="col-md-12 fixed-zone">
                                    <!-- begin:: fixed widget -->
                                    <div v-for="widget in listWidgetsOutsideDraggableZone" :key="widget.id" :id="widget.id ? 'vd_widget_'+widget.id : ''" >
                                        <app-genericwidgettile v-bind:widgetId="widget.id"></app-genericwidgettile>
                                    </div>
                                    <!-- end:: fixed widget -->
                                </div>
                                <!-- end:: non draggable zone -->
                            </div>
                            <!-- end:: widget row -->

                        </div>
                        <!-- end:: dashboard content -->

                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>

        <app-addeditwidgetmodal ref="addWidgetModal"></app-addeditwidgetmodal>
        <app-widgetdetailmodal></app-widgetdetailmodal>
        <app-addgeonotificationtowidget></app-addgeonotificationtowidget>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import genericWidgetTile from "./genericWidgetTile.vue";
import addEditWidgetModal from "./addEditWidgetModal.vue";
import widgetDetailModal from "./widgetDetailsModal.vue";
import AddGeonotificationModal from "../modals/addgeonotificationonwidgetmodal";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            dashboardId: this.$route.params.dashboardId,
            buttonColor: "#fd397a",
            listCounterWidgets: [],
            listThresholdWidgets: [],
            listBarChartWidgets: [],
            listPieChartWidgets: [],
            listHeatmapWidget: [],
            listChartWidgets: [],
            listPatientFlowWidget: [],
            listOtherWidget_width6: [],
            listWidgetsByZone: [[], [], []],  // Array of 3 column zones of widgets
            listWidgetsOutsideDraggableZone: [], // Array of widgets which are outside of draggable zone (like heatMap and people flow)
            isDraggableToInit: false,
            isDragged: false,
            widgetTitleWidth: 0,
            nbDashboardWidgets: 0,
            draggableSwappable: null,
            now: new Date(Date.now()),
            nowIntervalId: null,
            displayLastRefreshInfo: null,
            darkMode: false,
            refreshInterval: null
        };
    },
    created: function() {
        console.log("Component(viewDashboard)::created() - called");

        var colorPalette = [
            "#007bff",
            "#6610f2",
            "#6f42c1",
            "#e83e8c",
            "#dc3545",
            "#fd7e14",
            "#ffc107",
            "#28a745",
            "#20c997",
            "#17a2b8",
            "#e4c52d",
            "#5867dd",
            "#0abb87",
            "#5578eb",
            "#ffb822",
            "#fd397a"
        ];
        this.buttonColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];

        var payload = {
            siteId     : this.siteId,
            dashboardId: this.dashboardId
        };
        this.getDashboardById(payload);

        // Load list of building, floors, geofence, this site, widget types;
        this.setWidgetCreationModeTo(false);
        this.unsetWidgetToEdit();

        this.nowIntervalId = setInterval((function(self) {
            return function() {
                self.now = new Date(Date.now());
            }
        })(this), 1000);
    },
    mounted: function() {
        console.log("Component(viewDashboard)::mounted() - called");
        KTLayout.init();
        $("body").addClass("kt-aside--minimize");
        $(".dropdown-submenux").css({"position": "relative"});
        $(".dropdown-submenux .dropdown-menu").css({
            "top": "0",
            "margin-top": "-1px"
        });
        $('.dropdown-submenux a.ri-item').on("click", function(e){
            $(this).next('ul').toggle();
            e.stopPropagation();
            e.preventDefault();
        });
        $('.dropdown').on('hide.bs.dropdown', function(e){
            $(".dropdown-submenux .dropdown-menu").hide()
        });
    },
    destroyed: function() {
        console.log("Component(viewDashboard)::destroyed() - called");
        this.resetDashboardsState();
        this.resetBuildingsState();
        this.resetGeofencesState();
        this.setDarkMode(false);
        clearInterval(this.nowIntervalId);

        this.destroyDraggable();

        $("body").removeClass("kt-aside--minimize");
        $("body").removeClass("kt-aside--minimize-hover");

        $("body").removeClass("bodyDark");
        $(".portletCounter").removeClass("portletDark");
        $(".barChart").removeClass("portletDark");
        $(".heatmapChart").removeClass("portletDark");
        $(".pieChart").removeClass("portletDark");
        $(".subHeaderGridDash").removeClass("subHeaderGridDashDark").addClass("subHeaderGridDashLight");
        $(".genericWidgetTitle").removeClass("textWhiteForDark");
        $("text tspan").removeClass("textWhiteForDark");
        $(".moreButtonChart").removeClass("textWhiteForDark");
        $(".moreButtonChartMain").removeClass("backgroundMoreBtnDarkHover");
        $(".lastRefreshBloc").removeClass("textWhiteForDark");
        $(".btnCloseDash").removeClass("btnCloseDashDark");
        $("#dashboardTitle").removeClass("textWhiteForDark");
        $(".btnMoreDash").removeClass("btnMoreDashStyle");
        $(".kt-footer").removeClass("bodyDark");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        siteBuildings() {
            // currentDashboardWidgets is set by the call to this.getSiteBuildingsAndTheirFloors()
            console.log("Component(viewDashboard)::watch(siteBuildings) called");
            if (this.siteBuildings){
                var payload = { siteId: this.siteId, dashboardId: this.dashboardId };
                this.getAllWidgetsOfDashboard(payload);
            }
        },

        showAddGeonotificationModal: function(state) {
            console.log("Component(viewDashboard)::watch(showAddGeonotificationModal) called");
            if (state) {
                $("#addgeonotificationmodal").modal("show");
            } else {
                $("#addgeonotificationmodal").modal().hide();
            }
        },

        currentDashboardWidgets: {
            immediate: true,
            deep: true,
            handler: function() {
                // currentDashboardWidgets is set by the call to this.getAllWidgetsOfDashboard
                console.log("Component(viewDashboard)::currentDashboardWidgets - handler ");
                //this.listPieChartWidgets = [];
                //this.listBarChartWidgets = [];
                this.listCounterWidgets = [];
                this.listThresholdWidgets = [];
                this.listChartWidgets = [];
                this.listHeatmapWidget = [];
                this.listOtherWidget_width6 = [];
                var widgetROI;

                // Array of 3 column zones of widgets
                this.listWidgetsByZone = [[], [], []];

                if (this.currentDashboardWidgets.length !== this.nbDashboardWidgets) {
                    this.nbDashboardWidgets = this.currentDashboardWidgets.length;
                    this.isDraggableToInit = true;
                } else {
                    this.isDraggableToInit = false;
                }

                //console.log("Watcher of 'currentDashboardWidgets' in 'viewDashboard.vue' got fired!");
                this.currentDashboardWidgets.forEach(function(widgetObject) {
                    let displayType = (widgetObject && widgetObject.widgetType ? widgetObject.widgetType.displayType : "");
                    switch (displayType) {
                        case 'Pie_Chart':
                            this.listChartWidgets.push(widgetObject.id);
                            break;
                        case 'Bar_Chart':
                            this.listChartWidgets.push(widgetObject.id);
                            break;
                        case "Number":
                            this.listCounterWidgets.push(widgetObject.id);
                            break;
                        case "Threshold":
                            this.listThresholdWidgets.push(widgetObject.id);
                            break;
                        case 'Heatmap':
                            this.listOtherWidget_width6.push(widgetObject.id);
                            break;
                        case "ROI":
                            widgetROI = widgetObject.id;
                            break;
                        case "People_Flow":
                            this.listOtherWidget_width6.push(widgetObject.id);
                            break;
                        default:
                            console.log("Component(viewDashboard)::currentDashboardWidgets - handler - Unknow display type '" + displayType + "' of widget", widgetObject);
                            break;
                    }
                }, this);

                if (widgetROI) {
                    this.listCounterWidgets.push(widgetROI)
                }

                if (this.currentDashboardWidgets.length > 0) {
                    // Range widgets by column zone and by type on the same row if possible
                    this.setWidgetsPosition();
                }
            }
        },

        currentDashboard: {
            deep: true,
            immediate: true,
            handler(newValue) {
                // currentDashboard is set by the call to this.getDashboardById()
                console.log("Component(viewDashboard)::currentDashboard - handler ");
                if (newValue) {
                    this.refreshInterval = (parseInt(newValue.refreshInterval) || null);
                    this.darkMode = newValue.darkModeOn || false;
                    this.onChangeDarkMode(false);
                    // Get all buildings and floors
                    const payload = {
                        siteId: this.$route.params.siteId,
                        includeImage: false
                    }
                    this.getSiteBuildingsAndTheirFloors(payload);
                } else {
                    this.refreshInterval = null;
                }
            }
        },

        lastRefreshTime: function(newValue) {
            if (newValue) {
                this.computeLastRefreshTime()
            }
        },

        now: function() {
            this.computeLastRefreshTime()
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentDashboard",
            "currentDashboardWidgets",
            "lastRefreshTime",
            "siteBuildings",
            "showAddGeonotificationModal"
        ]),

        // Use by v-for when this.listWidgetsByZone has been initialized
        arrayWidgetsByZone: function() {
            //console.log("Component(viewDashboard)::arrayWidgetsByZone() - called ");
            this.$nextTick(() => {
                // nexTick allows to execute delayed callback after the next DOM update cycle ends
                console.log("Component(viewDashboard)::arrayWidgetsByZone() - nextTick ");
                // Init draggable zones when DOM has been updated (in case of v-for)
                if (!this.isEmptyWidgetsByZone()) {
                    this.initDraggable();
                }
            });
            return this.listWidgetsByZone;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "setDarkMode",
            "getAllWidgetsOfDashboard",
            "showDeleteModal",
            "resetDashboardsState",
            "resetBuildingsState",
            "resetGeofencesState",
            "getDashboardById",
            "getAllWidgetTypes",
            "getSiteBuildingsAndTheirFloors",
            "setWidgetCreationModeTo",
            "unsetWidgetToEdit",
            "updateDashboard",
            "updateDashboard",
            "updateWidgetsPosition"
        ]),

        onAddWidget: function() {
            this.setWidgetCreationModeTo(true);
        },

        computeLastRefreshTime() {
            this.setWidgetTitleWidth();
            if (this.lastRefreshTime && this.lastRefreshTime <= this.now) {
                this.displayLastRefreshInfo = moment(this.lastRefreshTime).from(moment(this.now))
            } else {
                this.displayLastRefreshInfo = "--"
            }
        },

        onChangeDarkMode(withRemoteUpdate) {
            this.setDarkMode(this.darkMode);
            if (withRemoteUpdate) {
                this.updateDisplayMode();
            }

            if (this.darkMode) {
                $("body").addClass("bodyDark");
                $(".portletCounter").addClass("portletDark");
                $(".barChart").addClass("portletDark");
                $(".heatmapChart").addClass("portletDark");
                $(".pieChart").addClass("portletDark");
                $(".subHeaderGridDash").addClass("subHeaderGridDashDark");
                $(".genericWidgetTitle").addClass("textWhiteForDark");
                $("text tspan").addClass("textWhiteForDark");
                $(".moreButtonChart").addClass("textWhiteForDark");
                $(".moreButtonChartMain").addClass("backgroundMoreBtnDarkHover");
                $(".lastRefreshBloc").addClass("textWhiteForDark");
                $(".btnCloseDash").addClass("btnCloseDashDark");
                $("#dashboardTitle").addClass("textWhiteForDark");
                $(".btnMoreDash").addClass("btnMoreDashStyle");
                $(".kt-footer").addClass("bodyDark");
            } else {
                $("body").removeClass("bodyDark");
                $(".portletCounter").removeClass("portletDark");
                $(".barChart").removeClass("portletDark");
                $(".heatmapChart").removeClass("portletDark");
                $(".pieChart").removeClass("portletDark");
                $(".subHeaderGridDash").removeClass("subHeaderGridDashDark").addClass("subHeaderGridDashLight");
                $(".genericWidgetTitle").removeClass("textWhiteForDark");
                $("text tspan").removeClass("textWhiteForDark");
                $(".moreButtonChart").removeClass("textWhiteForDark");
                $(".moreButtonChartMain").removeClass("backgroundMoreBtnDarkHover");
                $(".lastRefreshBloc").removeClass("textWhiteForDark");
                $(".btnCloseDash").removeClass("btnCloseDashDark");
                $("#dashboardTitle").removeClass("textWhiteForDark");
                $(".btnMoreDash").removeClass("btnMoreDashStyle");
                $(".kt-footer").removeClass("bodyDark");
            }
        },

        switchDarkMode() {
            this.darkMode = !this.darkMode;
            this.onChangeDarkMode(true);
        },

        updateRefreshInterval() {
            this.updateDashboardInfo();
        },

        updateDisplayMode() {
            this.updateDashboardInfo();
        },

        updateDashboardInfo() {
            var data = {
                siteId: this.siteId,
                dashboardId: this.dashboardId,
                refreshInterval: parseInt(this.refreshInterval),
                stayOnPage: true,
                name: this.currentDashboard.name,
                darkModeOn: this.darkMode
            };
            this.updateDashboard(data);
        },

        /**
         * Initialization of draggable zones
         */
        initDraggable() {
            var self = this;

            if (!this.isDraggableToInit) {
                console.log("Component(viewDashboard)::initDraggable() - already initialized ");
                return;
            }

            console.log("Component(viewDashboard)::initDraggable() - called ");

            // First remove empty widget generate by v-for in 'draggable-zone'
            $('.emptyWidget').remove();

            // containers should be a NodeList (like document.querySelectorAll('.draggable-zone')) or an array => Apply get to jquery element
            var containers = $('.draggable-zone').get();
            if (containers.length === 0) {
                console.log("Component(viewDashboard)::initDraggable() - No draggable zone");

            } else {
                this.destroyDraggable();

                this.isDraggableToInit = false;

                this.draggableSwappable = new Sortable.default(
                    // start container on drag start
                    containers,
                    // options
                    {
                        draggable: '.draggable',
                        handle: '.draggable .draggable-handle',
                        // cf. https://github.com/Shopify/draggable/tree/master/src/Draggable/Plugins/Mirror
                        mirror: {
                            // specify where the mirror should be appended to (it can be a css selector, a HTMLElement or a function that returns a HTMLElement)
                            appendTo: 'viewDashboard_widgets_row',
                            // If enabled, the source elements height and width will be applied to the mirror
                            constrainDimensions: true
                        }
                    }
                );
                // --- Draggable events ---
                // this.draggableSwappable.on('sortable:start', (evt) => {
                //     console.log("Component(viewDashboard)::initDraggable() - event sortable:start - evt:", evt);
                // });
                // this.draggableSwappable.on('sortable:sort', (evt) => {
                //     console.log("Component(viewDashboard)::initDraggable() - event sortable:sort - evt:", evt);
                // });
                // this.draggableSwappable.on('sortable:sorted', (evt) => {
                //     console.log("Component(viewDashboard)::initDraggable() - event sortable:sorted - evt:", evt);
                // });
                this.draggableSwappable.on('sortable:stop', (evt) => {
                    //console.log("Component(viewDashboard)::initDraggable() - event sortable:stop - evt:", evt);
                    const oldZone = this.getZoneNumberOfContainerName(evt.oldContainer.id);
                    const newZone = this.getZoneNumberOfContainerName(evt.newContainer.id);
                    const oldIndex = parseInt(evt.oldIndex, 10);
                    const newIndex = parseInt(evt.newIndex, 10);
                    if (newZone != oldZone || newIndex != oldIndex) {
                        console.log("Component(viewDashboard)::initDraggable() - Event sortable:stop - Widget move from ('draggable-zone' " + oldZone + ", 'draggable' index ", + oldIndex + ") to ('draggable-zone' " + newZone + ", 'draggable' index ", + newIndex + ")");
                        // Widgets are dragged
                        self.isDragged = true;
                        self.onUpdateWidgetsPosition();
                    }
                });

            }
        },

        destroyDraggable() {
            if (this.draggableSwappable) {
                // Case of added or deleted widget, destroy the previous draggable zone to take into account new widgets in zone
                console.log("Component(viewDashboard)::destroyDraggable() - destroy old");
                this.draggableSwappable.destroy();
                this.draggableSwappable = null;
                // Widgets are dragged
                this.isDragged = true;
            }
        },

        /**
         * Set the position of the widgets according to currentDashboardWidgets
         */
        setWidgetsPosition() {
            if (this.isEmptyWidgetsByZone()) {
                console.log("Component(viewDashboard)::setWidgetsPosition() - called ");

                let listWidgetsWithoutZonePosition = [];
                this.listWidgetsByZone = [[], [], []];
                this.listWidgetsOutsideDraggableZone = [];

                // Set position of widget if it is defined
                for (let i = 0; i < this.currentDashboardWidgets.length; i++) {
                    let position = this.currentDashboardWidgets[i].position;
                    let displayType = (this.currentDashboardWidgets[i].widgetType ? this.currentDashboardWidgets[i].widgetType.displayType : "");
                    if (this.isDraggableDisplayType(displayType)) {
                        // Add widget in list of widgets by zone if position is set and not already used
                        if (position && Object.keys(position).length > 0 && !this.listWidgetsByZone[position.dragZone][position.dragIndex]) {
                            this.listWidgetsByZone[position.dragZone][position.dragIndex] = {
                                id: this.currentDashboardWidgets[i].id,
                                type: displayType
                            }
                        } else {
                            listWidgetsWithoutZonePosition.push({
                                id: this.currentDashboardWidgets[i].id,
                                type: displayType
                            });
                        }
                    } else {
                        // Add widget in the list of widgets which are outside of draggable zone (like heatMap and people flow)
                        this.listWidgetsOutsideDraggableZone.push({
                            id: this.currentDashboardWidgets[i].id,
                            type: displayType
                        })
                    }
                }
                // Case of empty widget position
                if (listWidgetsWithoutZonePosition.length > 0) {
                    // For each widgets in draggable zone, add widget without position in the zone holes
                    for (let col = 0; col < this.listWidgetsByZone.length; col++) {
                        for (let row = 0; row < this.listWidgetsByZone[col].length; row++) {
                            // Replace empty widget with a widget without position
                            if (!this.listWidgetsByZone[col][row]) {
                                this.listWidgetsByZone[col][row] = listWidgetsWithoutZonePosition[0];
                                listWidgetsWithoutZonePosition.splice(0, 1);
                                if (listWidgetsWithoutZonePosition.length === 0) {
                                    break;
                                }
                            }
                        }
                        if (listWidgetsWithoutZonePosition.length === 0) {
                            break;
                        }
                    }
                    // Add widget without position at the end of draggable zone
                    if (listWidgetsWithoutZonePosition.length > 0) {
                        this.addWidgetsInEndZone(listWidgetsWithoutZonePosition);
                    }
                    // New widget position to save
                    if (listWidgetsWithoutZonePosition.length === 0) {
                        this.isDragged = true;
                    }
                }
                // case of all widgets without position
                if (this.isEmptyWidgetsByZone()) {
                    // Range widgets by column zone and by type on the same row if possible
                    let column = 0;
                    column = this.addDashboardWidgetsByZoneAndType(column, "Number");
                    column = this.addDashboardWidgetsByZoneAndType(column, "ROI");
                    column = this.addDashboardWidgetsByZoneAndType(column, "Pie_Chart");
                    column = this.addDashboardWidgetsByZoneAndType(column, "Bar_Chart");
                    column = this.addDashboardWidgetsByZoneAndType(column, "Threshold");
                    //column = this.addDashboardWidgetsByZoneAndType(column, "Heatmap");
                    //column = this.addDashboardWidgetsByZoneAndType(column, "People_Flow");
                } else {
                    // In case of empty draggable zone, initialize this zone with empty object to avoid undefined widget in v-for class="draggable"
                    for (let indexZone = 0; indexZone < this.listWidgetsByZone.length; indexZone++) {
                        for (let indexDraggable = 0; indexDraggable < this.listWidgetsByZone[indexZone].length; indexDraggable++) {
                            if (!this.listWidgetsByZone[indexZone][indexDraggable]) {
                                this.listWidgetsByZone[indexZone][indexDraggable] = {};
                            }
                        }
                    }
                }
            }
        },

        /**
         * Add currentDashboardWidgets widgets in the list of widget by column zone (this.listWidgetsByZone).
         * There are 3 column zones. The widgets are grouped by row and by displayType if it is possible.
         */
        addDashboardWidgetsByZoneAndType(column, type) {
            let newColumn = column;
            for (let i = 0; i < this.currentDashboardWidgets.length; i++) {
                let displayType = (this.currentDashboardWidgets[i].widgetType ? this.currentDashboardWidgets[i].widgetType.displayType : "");
                if (this.isDraggableDisplayType(displayType) && displayType === type) {
                    let zone = newColumn % 3;
                    this.listWidgetsByZone[zone].push( {
                        id: this.currentDashboardWidgets[i].id,
                        type: type
                    } );
                    newColumn++;
                }
            }
            return newColumn;
        },

        /**
         * Add a list of widgets at the end of the list of widget by column zone (this.listWidgetsByZone).
         */
        addWidgetsInEndZone(listWidgetsToAdd) {
            while (listWidgetsToAdd.length > 0) {
                // Add widgets at the end of each zone
                let zone = this.getFirstFreeZone();
                // Add new widget in global list listWidgetsByZone
                this.listWidgetsByZone[zone].push( {
                    id: listWidgetsToAdd[0].id,
                    type: listWidgetsToAdd[0].type
                } );
                // remove added widget from list to add
                listWidgetsToAdd.splice(0, 1);
            }
        },

        /**
         * Get the first free zone of the list of widgets.
         */
        getFirstFreeZone() {
            let newColumn = 0;
            let nbZones = this.listWidgetsByZone.length;
            if (nbZones > 0) {
                // Get the last zone where to add a widget
                let minNbWidgetsInZone = 9999;
                for (let column = 0; column < nbZones; column++) {
                    let nbWidgetsInZone = this.listWidgetsByZone[column].length;
                    if (nbWidgetsInZone < minNbWidgetsInZone) {
                        // The last zone to update is the column with the minimum of widgets
                        minNbWidgetsInZone = nbWidgetsInZone;
                        newColumn = column;
                    }
                }
            }
            return newColumn % 3;
        },

        /**
         * Is there no widgets in draggable-zone ?
         */
        isEmptyWidgetsByZone() {
            let isEmpty = true;
            if (Array.isArray(this.listWidgetsByZone) && this.listWidgetsByZone.length === 3 &&
                ((Array.isArray(this.listWidgetsByZone[0]) && this.listWidgetsByZone[0].length > 0) ||
                (Array.isArray(this.listWidgetsByZone[1]) && this.listWidgetsByZone[1].length > 0) ||
                (Array.isArray(this.listWidgetsByZone[2]) && this.listWidgetsByZone[2].length > 0))) {
                isEmpty = false;
            }
            return isEmpty;
        },

        /**
         * Get the number of a draggable-zone identifier.
         * @param {string} name The identifier name of the draggable-zone class (like vd_widgets_zone_<zoneNumber>)
         * @returns the number of draggable zone name
         */
        getZoneNumberOfContainerName(name) {
            // vd_widget_zone_<number>
            let dragNumber = null;
            if (name) {
                const splittedName = name.split('_');
                if (splittedName.length > 3 && splittedName[3] !== "undefined") {
                    dragNumber = parseInt( splittedName[3], 10);
                }
            }
            return dragNumber;
        },

        /**
         * Get the widget id of a draggable identifier.
         * @param {string} name The identifier name of the draggable-class (like vd_widget_<widgetId>)
         * @returns the index of draggable name
         */
        getWidgetIdOfContainerName(name) {
            // vd_widget_<widgetId>
            let widgetId = null;
            if (name) {
                const splittedName = name.split('_');
                if (splittedName.length > 2 && splittedName[2] !== "undefined") {
                    widgetId = splittedName[2];
                }
            }
            return widgetId;
        },

        /**
         * Update widget positions on server.
         */
        onUpdateWidgetsPosition() {
            if (!this.isDragged) {
                console.log("Component(viewDashboard)::onUpdateWidgetsPosition() - No widgets were dragged");
                return;
            }
            // isDragged true: Widget were dragged => New position to save
            var self = this;
            let draggableWidgetPositions = [];
            let updatedWidgetsByZone = [[], [], []];

            // Save the position of widget in the dashboard zone: the draggable zone (displayed column in non responsive display), and the index in the draggable zone (displayed row in non responsive display)
            $(".draggable-zone").each(function(indexZone, valueZone) {
                let draggableElements = self.draggableSwappable.getDraggableElementsForContainer(valueZone);
                $(draggableElements).each(function(indexDraggable, valueDraggable) {
                    const widgetId = self.getWidgetIdOfContainerName(valueDraggable.id);
                    if (widgetId) {
                        //console.log("Component(viewDashboard)::onUpdateWidgetsPosition() - widgetId=" + widgetId + " - indexZone=" + indexZone + " - indexDraggable=" + indexDraggable);
                        draggableWidgetPositions.push({
                            widgetId: widgetId,
                            dragZone: indexZone,
                            dragIndex: indexDraggable
                        });
                        let widgetObject = self.currentDashboardWidgets.find(element => element.id === widgetId);
                        updatedWidgetsByZone[indexZone][indexDraggable] = {
                            id: widgetId,
                            type: (widgetObject && widgetObject.widgetType ? widgetObject.widgetType.displayType : "")
                        };
                    } else {
                        console.log("Component(viewDashboard)::onUpdateWidgetsPosition() - No widget in 'draggable-zone' " + indexZone + ", 'draggable' index " + indexDraggable);
                    }
                });
            });

            // Send updated positions
            if (draggableWidgetPositions.length > 0) {
                // Update widgets zone list
                this.listWidgetsByZone = updatedWidgetsByZone;
                // Send Update widgets position on backend
                const payload = {
                    dashboardId: this.dashboardId,
                    siteId: this.siteId,
                    positions: draggableWidgetPositions
                };
                console.log("Component(viewDashboard)::onUpdateWidgetsPosition() - Update drag widget positions with: ", payload);
                this.updateWidgetsPosition(payload);
            } else {
                console.log("Component(viewDashboard)::onUpdateWidgetsPosition() - No position to update for dashboard " + this.dashboardId);
            }

            // Widgets are no longer draggable
            this.isDragged = false;
        },

        onCloseDashboard() {
            this.$router.push({ name: "dashboards" });
        },

        // Set widget title max width
        setWidgetTitleWidth() {
            // Get width of header
            let width = $(".genericWidgetHeader").width();
            if (width) {
                let titleWidth = width * 3/4;
                if (titleWidth !== this.widgetTitleWidth) {
                    // Set width of title
                    $(".genericWidgetTitle").width(titleWidth);
                    this.widgetTitleWidth = titleWidth;
                    //console.log("Component(viewDashboard)::setWidgetTitleWidth() - title width=" + titleWidth);
                }
            }
        },

        isDraggableDisplayType(displayType) {
            let isDraggable;
            switch (displayType) {
                case 'Pie_Chart':
                case 'Bar_Chart':
                case "Number":
                case "Threshold":
                case "ROI":
                    isDraggable = true;
                    break;
                case 'Heatmap':
                case "People_Flow":
                    isDraggable = false;
                    break;
                default:
                    isDraggable = false;
            }
            return isDraggable;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-genericwidgettile": genericWidgetTile,
        "app-addeditwidgetmodal": addEditWidgetModal,
        "app-widgetdetailmodal": widgetDetailModal,
        "app-addgeonotificationtowidget": AddGeonotificationModal
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        console.log("Component(viewDashboard)::beforeRouteLeave() - called");
        this.onUpdateWidgetsPosition();
        next();
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.generic-widget-tile-plus {
    height: 100%;
    margin: 5px;
    font-size: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* ------------------------------------------------- */
/* From Bootstrap v4.6.0 (https://getbootstrap.com/) */
.draggable {
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    outline: 0!important
}

.draggable.draggable-mirror {
    opacity: .8;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    border: 2px dashed #6c7293!important;
    height: 383px !important;
}

.draggable.draggable--original {
    opacity: 0!important
}

.draggable.draggable-source--is-dragging.draggable--over {
    opacity: 0!important
}

.draggable .draggable-handle {
    cursor: move;
}

.draggable-zone {
    outline: 0!important
}
/* ------------------------------------------------- */
</style>

<style>
.bodyDark {
    background-color: #16113A !important;
}
.portletDark {
    background-color: #272953 !important;
}
.textWhiteForDark {
    color: white !important;
    font-weight: 400 !important;
}
.backgroundMoreBtnDarkHover:hover {
    background: #7a84ab !important;
}
.dashboardTitleDarkMode {
    color: white !important;
}
.btnCloseDashDark {
    color: white;
    border-color: white;
}
.subHeaderGridDashLight {
    background-color: #e5e6ea !important;
    box-shadow: none !important;
}
.subHeaderGridDashDark {
    background-color: #16113A !important;
    box-shadow: none !important;
}
.btnMoreDashStyle {
    border-color: white;
}
.customActiveDropdown:active {
    color: #6c7293 !important;
    text-decoration: none !important;
    background-color: #f7f8fa !important;
}
.intervalSelectDropdown li {
    height: 37px;
}
.intervalSelectDropdown li:active{
    color: #6c7293 !important;
    text-decoration: none !important;
    background-color: #f7f8fa !important;
}
</style>