<template>
    <div id="autocalibrationTagsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="autocalibrationtagsList"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="act_autocalibrationtagsListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <button @click="openImportAutocalibrationTagsModal" id="act_importTagsFromXLSButton" class="btn btn-success btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                                        {{ $t("common_importFromXLSFile") }}
                                    </button>
                                    <span class="kt-margin-l-10">{{ $t("common_or") }}</span>
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newAutoCalibrationTag' }" id="autocalibrationtags_createNewAutocalibrationTagButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("act_createNewAutoCalibrationTag") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div v-if="isBeaconOALBTExists" class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="flaticon-warning kt-font-warning"></i></div>
                                    <div class="alert-text kt-font-warning kt-font-bold">
                                        <span>{{ $t("act_warningBLEBeacon") }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("act_autocalibrationtagsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div v-show="dataBLEBeaconsSelected.length" class="kt-portlet__head-wrapper kt-margin-r-10">
                                                    <div class="dropdown">
                                                        <button class="btn btn-sm btn-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="la la-ellipsis-h kt-input-icon__icon--left" style="font-weight: 900"></i>
                                                            Action
                                                        </button>
                                                        <div class="dropdown-menu kt-margin-l-10 kt-margin-r-10" aria-labelledby="dropdownMenuButton">
                                                            <div class="kt-font-solid kt-margin-l-10" >{{ $t("common_choseAction") }}</div>
                                                            <span class="dropdown-item cursorPointer" @click="onBulkDeleteAutocalibrationTag()"> <i class="kt-font-danger la la-trash"></i>{{ $t("common_delete") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchBySerial')" @keyup="onSearch" v-model="searchedValue" id="act_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="exportautoTagsModal" id="autotags_exportTagsFromXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="AUTOCALIBRATION_TAG" mainTextKey="act_notFound" subTextKey="act_notFoundSubLabel" imgName="empty_autocalibrationtag.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="autoCalibrationTags_datatable"></div>
                                            <!--end: Datatable -->
                                            <div v-if="isBatteryStatusHelpDisplayed" class="kt-widget19__text" style="color: rgb(118, 121, 140); margin-bottom: 17px; margin-left: 21px;">
                                                <sup>*</sup> {{ $t("actag_EstimatedBatteryHelpText") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>
                    <!-- begin:: Modal -->
                    <app-importautocalibrationtagsfromXLSmodal ref="importAutocalibrationTagsModal"></app-importautocalibrationtagsfromXLSmodal>
                    <!-- end:: Modal -->
                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";
import ImportAutocalibrationTagsModal from "../modals/importautocalibrationtagsmodal.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteAutoCalibrationTagsTable: null,
            isEmptyList: false,
            searchedValue: this.$route.params.search || "",
            isBatteryStatusHelpDisplayed: false,
            isBeaconOALBTExists: false,
            dataBLEBeaconsSelected: []
        };
    },
    created: function() {
        console.log("Component(AutoCalibrationTags)::created() - called");
        if (this.createAnotherAutocalibrationTag) {
            this.$router.push({ name: "newAutoCalibrationTag" });
        }
        let data = {
            includeImage: false
        }
        this.getUserRolesAndSites(data);
    },
    mounted: function() {
        console.log("Component(AutoCalibrationTags)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    beforeDestroy: function() {
        console.log("Component(AutoCalibrationTags)::beforeDestroy() - called");
        this.searchedValue = "";
        // Reset component data
        if (this.siteAutoCalibrationTagsTable) {
            this.siteAutoCalibrationTagsTable.destroy();
            this.siteAutoCalibrationTagsTable = null;
        }
        // Reset store
        this.resetAutocalibrationTagsState();
    },
    destroyed: function() {
        console.log("Component(AutoCalibrationTags)::destroyed() - called");
        this.dataBLEBeaconsSelected = [];
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAutoCalibrationTags: {
            handler() {
                console.log("Component(AutoCalibrationTags)::watch(siteAutoCalibrationTags) called");

                const autoCalibrationTagOALBT = this.siteAutoCalibrationTags && this.siteAutoCalibrationTags.find(tag => {
                    return tag.tagType && tag.tagType.reference === "OAL-BT"
                });
                this.isBeaconOALBTExists = autoCalibrationTagOALBT ? true : false;
                this.initKtDataTable();
                //search directly a specific beacon given by his Serial Number
                if(this.searchedValue){
                    setTimeout(() => {
                        this.onSearch();
                    }, 200)
                }
            },
            deep: true
        },
        user: function(user) {
            if (user) {
                console.log("Component(AutoCalibrationTags)::watch(user) called with :", user);
                this.initKtDataTable();
                this.setAutoCalibrationTagSelected();
            }
        },
        currentSite: function(site) {
            console.log("Component(AutoCalibrationTags)::watch(currentSite) called");
            // Load autocalibration tags from backend and store the result in sites store
            this.getSiteAutoCalibrationTags(this.siteId);
        },
        dataBulkDelete: function() {
            if(!this.dataBulkDelete || this.dataBulkDelete.length === 0) {
                this.dataBLEBeaconsSelected = [];
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAutoCalibrationTags", "currentSite", "createAnotherAutocalibrationTag", "user", "dataBulkDelete", "rolesBySiteId"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteAutoCalibrationTags",
            "showDeleteModal",
            "showImportAutocalibrationTagsModal",
            "resetAutocalibrationTagsState",
            "showBulkDeleteModal",
            "getUserRolesAndSites"
        ]),
        onSearch() {
            if (this.siteAutoCalibrationTagsTable) {
                this.siteAutoCalibrationTagsTable.search(this.searchedValue, "serialNumber");
            }
        },
        onEditAutoCalibrationTag: function(autoCalibrationTagId) {
            this.$router.push({
                name: "editAutoCalibrationTag",
                params: { autoCalibrationTagId: autoCalibrationTagId, siteId: this.siteId }
            });
        },
        onEditBuilding: function(buildingId) {
            this.$router.push({ name: "editBuilding", params: { buildingId: buildingId, siteId: this.siteId } });
        },
        onEditFloor: function(buildingId, floorId) {
            this.$router.push({ name: "editFloor", params: { siteId: this.siteId, buildingId: buildingId, floorId: floorId } });
        },
        onBulkDeleteAutocalibrationTag: function() {
            let self = this;
            if (self.dataBLEBeaconsSelected) {
                self.showBulkDeleteModal({
                    textContent: i18n.t("act_bulkDeleteAutoCalibrationTagConfirmText"),
                    actionName: "BULK_DELETE_AUTOCALIBRATIONTAG",
                    params: { siteId: self.siteId, data: self.dataBLEBeaconsSelected }
                })
            }
        },
        registerEventsOnSiteAutoCalibrationTagsTable() {
            var self = this;
            $("#autoCalibrationTags_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteAutoCalibrationTagButton")
                    .off()
                    .on("click", function() {
                        let autoCalibrationTagId = $(this).attr("data-autocalibrationtagid");
                        let autoCalibrationTagSerialNumber = $(this).attr("data-autocalibrationserialnumber");
                        console.log("Component(AutoCalibrationTags)::registerEventsOnSiteAutoCalibrationTagsTable(deleteAutoCalibrationTagButton) clicked. AutoCalibrationTagId = " + autoCalibrationTagId);
                        self.showDeleteModal({
                            textContent: i18n.t("act_deleteAutoCalibrationTagConfirmText", { autoCalibrationTagSerialNumber: autoCalibrationTagSerialNumber }),
                            actionName: "DELETE_AUTOCALIBRATIONTAG",
                            params: { siteId: self.siteId, autoCalibrationTagId: autoCalibrationTagId }
                        });
                    });
                $(".editAutoCalibrationTagButton")
                    .off()
                    .on("click", function() {
                        let autoCalibrationTagId = $(this).attr("data-autocalibrationtagid");
                        console.log("Component(AutoCalibrationTag)::registerEventsOnSiteAutoCalibrationTagsTable(editAutoCalibrationTagButton) clicked. AutoCalibrationTagId = " + autoCalibrationTagId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditAutoCalibrationTag(autoCalibrationTagId);
                    });
                $(".editBuildingButton")
                    .off()
                    .on("click", function() {
                        let buildingId = $(this).attr("data-buildingid");
                        console.log("Component(AutoCalibrationTag)::registerEventsOnSiteAutoCalibrationTagsTable(editBuildingButton) clicked. BuildingId = " + buildingId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditBuilding(buildingId);
                    });
                $(".editFloorButton")
                    .off()
                    .on("click", function() {
                        let buildingId = $(this).attr("data-buildingid");
                        let floorId = $(this).attr("data-floorid");
                        console.log("Component(SiteFloors)::registerEventsOnFloorsTable(editFloorButton) clicked. FloorId = " + floorId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditFloor(buildingId, floorId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });

            $("#autoCalibrationTags_datatable").on('kt-datatable--on-check', (event, args) => {

                if ($(".kt-datatable__head input[type='checkbox']").prop('checked')) {
                    self.dataBLEBeaconsSelected = [...self.siteAutoCalibrationTags];
                } else {
                    function isObjectInArray(arr, obj) {
                        return arr.some(item => JSON.stringify(item) === JSON.stringify(obj));
                    }
                    const autoCalibrationTag = JSON.parse(args);

                    if (!isObjectInArray(self.dataBLEBeaconsSelected, autoCalibrationTag)) {
                        self.dataBLEBeaconsSelected.push(autoCalibrationTag)
                    }
                }

                setTimeout(() => {
                    if (self.siteAutoCalibrationTags.length === self.dataBLEBeaconsSelected.length) {
                        $(".kt-datatable__head input[type='checkbox']").prop('checked', true)
                    } else {
                        $(".kt-datatable__head input[type='checkbox']").prop('checked', false)
                    }
                }, 50);

            });

            $(".kt-datatable__head input[type='checkbox']").on("change", () => {
                if (!$(".kt-datatable__head input[type='checkbox']").prop('checked')) {
                    self.dataBLEBeaconsSelected = [];
                }
            });

            $("#autoCalibrationTags_datatable").on('kt-datatable--on-uncheck', (event, args) => {

                $(".kt-datatable__head input[type='checkbox']").prop('checked', false)

                const autoCalibrationTags = _.map(args, item => JSON.parse(item));

                _.forEach(autoCalibrationTags, autoCalibrationTag => {
                    const index = _.findIndex(self.dataBLEBeaconsSelected, { id: autoCalibrationTag.id });
                    self.dataBLEBeaconsSelected.splice(index, 1);
                });
            });

            $("#autoCalibrationTags_datatable").on('kt-datatable--on-update-perpage', () => {
                self.setAutoCalibrationTagSelected();
            });

            $("#autoCalibrationTags_datatable").on('kt-datatable--on-goto-page', () => {
                self.setAutoCalibrationTagSelected();
            });
        },

        setAutoCalibrationTagSelected() {
            let self = this;
            setTimeout(() => {
                const autoCalibrationTagIds = _.map(self.dataBLEBeaconsSelected, autoCalibrationTag => autoCalibrationTag.id);
                for(let i = 0; i < $(".kt-datatable__body input[type='checkbox']").length; i++) {
                    if (autoCalibrationTagIds.includes(JSON.parse($(".kt-datatable__body input[type='checkbox']")[i]["value"]).id)) {
                        $(".kt-datatable__body input[type='checkbox']").eq(i).prop('checked', true);
                        $(".kt-datatable__body .kt-datatable__row").eq(i).addClass("kt-datatable__row--active");
                    }
                }

            }, 50);

            setTimeout(() => {
                if (self.siteAutoCalibrationTags.length === self.dataBLEBeaconsSelected.length) {
                    $(".kt-datatable__head input[type='checkbox']").prop('checked', true)
                } else {
                    $(".kt-datatable__head input[type='checkbox']").prop('checked', false)
                }
            }, 50);
        },

        openImportAutocalibrationTagsModal() {
            // Reset data in modal
            this.$refs.importAutocalibrationTagsModal.errorDisplayed = false;
            this.$refs.importAutocalibrationTagsModal.xlsData = null;
            this.$refs.importAutocalibrationTagsModal.isImportButtonDisabled = true;
            this.$refs.importAutocalibrationTagsModal.xlsErrors = null;
            this.$refs.importAutocalibrationTagsModal.xlsFile = null;
            this.$refs.importAutocalibrationTagsModal.isXlsFileIsEmpty = false;
            this.$refs.importAutocalibrationTagsModal.isSheetNotFound = false;
            // Force input file empty
            $("#importAutocalibrationTagsInputFile").val("");
            // Reset placeholder of label
            $("#importAutocalibrationTagsInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportAutocalibrationTagsModal();
        },
        exportautoTagsModal() {

            var data = [];
            // Creation of table header
            var header = [
                {name: "Beacon Mac Address", type: "STRING"},
                {name: "Type of Beacon", type: "STRING"},
                {name: "Serial Number", type: "STRING"},
                {name: "Battery Installation Date (YYYY-MM-DD)", type: "DATE"},
                {name: "Floor Elevation", type: "FLOAT"},
                {name: "Longitude", type: "STRING"},
                {name: "Latitude", type: "STRING"}
            ];
            this.siteAutoCalibrationTags.forEach(tag => {
                var tagInfo = [tag.macAddress, tag.tagType.name, tag.serialNumber, tag.batteryInstallationDate];
                // Checks if autotag is located
                if (tag.floor) {
                    tagInfo.push(tag.floorElevation);
                } else {
                    tagInfo.push(2.5);
                }

                // Checks the longitude and lattitude
                if (!_.isEmpty(tag.location)) {
                    tagInfo.push(tag.location.coordinates[0]);
                    tagInfo.push(tag.location.coordinates[1]);
                }
                else {
                    tagInfo.push("N/A");
                    tagInfo.push("N/A");
                }

                // Add in export data
                data.push(tagInfo);
            });
            // Call common vueHelper function
            commonVueHelper.exportsDataToExcel(data, header, "autocalibration-beacons", "beacons");
        },
        initKtDataTable() {
            var self = this;

            let data = [];
            if (this.siteAutoCalibrationTags && this.siteAutoCalibrationTags.length > 0) {
                data = this.siteAutoCalibrationTags.map((tag) => {
                    let finalTag = {...tag};
                    if (tag.tagType) {
                        finalTag.tagTypeFullRef = commonVueHelper.getPropFromTagType("fullRef", tag.tagType);
                    }
                    return finalTag;
                });
            }

            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: 20,
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "serialNumber",
                        title: i18n.t("act_autocalibrationtagSerialNumberLabel"),
                        default: "-",
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            if (row.serialNumber) {
                                var createdAt = moment(row.createdAt);
                                var now = moment();
                                var duration = moment.duration(now.diff(createdAt));
                                var minutes = duration.asMinutes();
                                if (minutes < 15) {
                                    return '\
                                            <div class="kt-user-card-v2">\
                                                <div class="kt-user-card-v2__details openResourceLink">\
                                                    <a class="editAutoCalibrationTagButton kt-link kt-user-card-v2__name" data-autoCalibrationtagid="' + row.id + '" href="javascript:;">' + row.serialNumber + "<span class='labelNew'>" + i18n.t("common_new") + "</span></a>\
                                                </div>\
                                            </div>\
                                        ";
                                } else {
                                    return '\
                                            <div class="kt-user-card-v2">\
                                                <div class="kt-user-card-v2__details openResourceLink">\
                                                    <a class="editAutoCalibrationTagButton kt-link kt-user-card-v2__name" data-autoCalibrationtagid="' + row.id + '" href="javascript:;">' + row.serialNumber + "</a>\
                                                </div>\
                                            </div>\
                                        ";
                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "tagTypeFullRef",
                        title: i18n.t("MAP_TAG_TYPE"),
                        textAlign: "left",
                        width: 200,
                        template: function(row) {
                            if (row.tagType) {
                                let tagTypeImg = commonVueHelper.getPropFromTagType("img", row.tagType);
                                return (
                                    `<div class="kt-user-card-v2">
                                        <img src="` + tagTypeImg + `" class="tagsIconPreview" />
                                        <div class="kt-user-card-v2__details" style="margin-left:8px;">
                                            <span>` + row.tagTypeFullRef + `
                                        </div>
                                    </div>`
                                );
                            }
                        }
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                            }
                        }
                    },
                    {
                        field: "location",
                        title: i18n.t("act_autoCalibrationTagLocation"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.building && row.floor) {
                                return (
                                    `
                                            <div class="kt-user-card-v2" style="display: inline-flex;">
                                                <div class="kt-user-card-v2__details marginLandR5 openResourceLink">
                                                    <a class="editBuildingButton kt-link kt-user-card-v2__name" data-buildingid="` +
                                        row.building.id +
                                        `" href="javascript:;">` +
                                        row.building.name +
                                        `</a>
                                                </div>
                                                /
                                                <div class="kt-user-card-v2__details marginLandR5 openResourceLink">
                                                    <a class="editFloorButton kt-link kt-user-card-v2__name" data-buildingid="` +
                                        row.building.id +
                                        `" data-floorid="` +
                                        row.floor.id +
                                        `" href="javascript:;">` +
                                        row.floor.name +
                                        `</a>
                                                </div>
                                            </div>
                                        `
                                );
                            } else {
                                return i18n.t("gtw_bleNotYetLocated");
                            }
                        }
                    },
                    {
                        field: "batteryInstallationDate",
                        title: i18n.t("tag_EstimatedBatteryStatusLabel"),
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        sortable: false,
                        template: function(row) {
                            if (row.hasOwnProperty("realBatteryStatusValue") && row.realBatteryStatusValue !== -1) {
                                if (row.realBatteryStatusValue < 30) {
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="10"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + row.realBatteryStatusValue +` %</div>`
                                    );
                                } else if (row.realBatteryStatusValue < 70 && row.realBatteryStatusValue >= 30) {
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="50"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + row.realBatteryStatusValue +` %</div>`
                                    );
                                } else {
                                    return (
                                        `<div class="kt-user-card-v2 marginT4BatStatus">
                                            <div class="progress" style="width: 30px; margin: 0 auto;">
                                                <div class="progress-bar bg-success" role="progressbar" style="width: `+ row.realBatteryStatusValue + `%" aria-valuenow="`+ row.realBatteryStatusValue + `" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="batteryStatusStr">` + row.realBatteryStatusValue +` %</div>`
                                    );
                                }
                            } else if (row.hasOwnProperty("batteryInstallationDate")){
                                if (row.tagType) {
                                    let batteryLifetime = row.tagType.batteryLifetime;
                                    let estimatedBatteryLifetimeEndDate = moment(row.batteryInstallationDate).add(batteryLifetime, "months");
                                    let estimatedBatteryLifetimeEndDateMinus6Months = moment(estimatedBatteryLifetimeEndDate).subtract(6, "months");
                                    let estimateBatteryMiddleLifetimeDate = moment(estimatedBatteryLifetimeEndDate).subtract(batteryLifetime / 2, "months");
                                    let now = moment();
                                    if (now.isAfter(estimatedBatteryLifetimeEndDate) || now.isAfter(estimatedBatteryLifetimeEndDateMinus6Months)) {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    } else if (now.isAfter(estimateBatteryMiddleLifetimeDate) && now.isBefore(estimatedBatteryLifetimeEndDateMinus6Months)) {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-warning" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="50"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    } else {
                                        return (
                                            `<div class="kt-user-card-v2 marginT4BatStatus">
                                                <div class="progress" style="width: 30px; margin: 0 auto;">
                                                    <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div class="batteryStatusStr">` + i18n.t("tag_EstimatedBatteryLabel") +`<sup>*</sup></div>`
                                        );
                                    }
                                } else {
                                    return i18n.t("common_unknown");;
                                }
                            } else {
                                return "N/A";
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if (self.currentSite.isInProduction) {
                                return (
                                    `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-autocalibrationtagid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editAutoCalibrationTagButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_unauthorizedAction") +
                                        `" type="button" data-autocalibrationtagid="` +
                                        row.id +
                                        `" data-autocalibrationserialnumber="` +
                                        row.serialNumber +
                                        `" class="btn btn-outline-danger btn-sm btn-icon disabled">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                );
                            } else {
                                return (
                                    `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-autocalibrationtagid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editAutoCalibrationTagButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_delete") +
                                        `" type="button" data-autocalibrationtagid="` +
                                        row.id +
                                        `" data-autocalibrationserialnumber="` +
                                        row.serialNumber +
                                        `" class="btn btn-outline-danger btn-sm btn-icon deleteAutoCalibrationTagButton">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                );
                            }
                        }
                    }
                ]
            };

            if (this.isAdminUser() && this.currentSite && this.currentSite.isInProduction === false) {
                options.columns.unshift({
                    field: "selector",
                    title: "",
                    width: 10,
                    overflow: "visible",
                    selector: {
                        class: 'kt-checkbox kt-checkbox--bold kt-checkbox--all',
                    },
                    autoHide: false,
                    template: function(row) {
                        return JSON.stringify(row)
                    }
                });
            }

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteAutoCalibrationTagsTable) {
                $("#autoCalibrationTags_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteAutoCalibrationTagsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteAutoCalibrationTagsTable = $("#autoCalibrationTags_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteAutoCalibrationTagsTable();
                this.isBatteryStatusHelpDisplayed = true;
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.isBatteryStatusHelpDisplayed = false;
            }
        },
        isAdminUser() {
            if (this.user) {
                const siteUserRole = this.rolesBySiteId ? this.rolesBySiteId[this.$route.params.siteId] : "";
                if (this.user.isSuperAdmin || this.user.isTSUser || siteUserRole === "ADMIN") {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-importautocalibrationtagsfromXLSmodal": ImportAutocalibrationTagsModal
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
<style>
.marginLandR5 {
    margin-left: 5px;
    margin-right: 5px;
}
</style>
