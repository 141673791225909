<template>
    <div id="deploymentChecking" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="deploymentChecking"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="troubleshooting_deploymentChecking"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->
                        <div
                            class="kt-content kt-grid__item kt-grid__item--fluid"
                        >
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <app-sitegatewaychecking :gatewaysChecking="gatewaysChecking" ></app-sitegatewaychecking>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <app-sitebeaconschecking :beaconsChecking="beaconsChecking" ></app-sitebeaconschecking>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import SiteGatewayChecking from "./../troubleshooting/gatewaychecking.vue";
import SiteBeaconsChecking from "./../troubleshooting/beaconschecking.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            gatewaysChecking: [],
            beaconsChecking : [],
            buildings : [],
            floors : [],
        };
    },
    created: function() {
        console.log("Component(deploymentchecking)::created() - called");
        const payload = {
            siteId: this.siteId,
            includeImage: false
        }
        this.getSiteBuildingsAndTheirFloors(payload);
    },
    mounted: function() {
        console.log("Component(deploymentchecking)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        this.resetSiteCheckingState();
        this.resetBuildingsState();
        next();
    },
    destroyed: function() {
        console.log("Component(deploymentchecking)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        //Check if there is buildings and floors created on site
        siteBuildings() {

            if(this.siteBuildings && this.siteBuildings.length > 0){

                for(let building of this.siteBuildings){
                    for(let floor of building.floors){

                        this.floors.push({
                            id : floor.id,
                            name : floor.name
                        });
                    }
                    this.buildings.push({
                        id : building.id,
                        name : building.name
                    });
                }
                this.getSiteGatewayChecking(this.siteId);
                this.getSiteBeaconsChecking(this.siteId);
            }else{
                this.gatewaysChecking = [];
                this.beaconsChecking = [];
            }
        },

        // If there is buildings and floors we can begin the checking
        siteGatewayChecking() {
            this.gatewayChecking = [];
            for (let gatewayChecking of this.siteGatewayChecking) {
                let floorName = "";
                for(let floor of this.floors){
                    if(floor.id === gatewayChecking.floorId) {
                        floorName = floor.name;
                        break;
                    }
                }
                const findBuilding = this.buildings.find((building) => {
                    if (building.id === gatewayChecking.buildingId) {
                        return building;
                    }
                });
                let buildingName = findBuilding && findBuilding.name ? findBuilding.name : "";
                this.gatewaysChecking.push({
                    ...gatewayChecking, floorName, buildingName
                });
            }
        },

        siteBeaconsChecking() {
            this.beaconsChecking = [];
            for (let beaconChecking of this.siteBeaconsChecking) {
                let floorName = "";
                for(let floor of this.floors){
                    if(floor.id === beaconChecking.floorId) {
                        floorName = floor.name;
                        break;
                    }
                }
                const findBuilding = this.buildings.find((building) => {
                    if (building.id === beaconChecking.buildingId) {
                        return building;
                    }
                });
                let buildingName = findBuilding && findBuilding.name ? findBuilding.name : "";
                this.beaconsChecking.push({
                    ...beaconChecking, floorName, buildingName
                });
            }
        }


    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --teAnoth
        ...mapGetters(["siteBuildings", "siteGatewayChecking","siteBeaconsChecking"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteGatewayChecking", "getSiteBuildings","getSiteBuildingsAndTheirFloors", "resetBuildingsState", "getSiteBeaconsChecking", "resetSiteCheckingState"]),
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-sitegatewaychecking" : SiteGatewayChecking,
        "app-sitebeaconschecking" : SiteBeaconsChecking
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
