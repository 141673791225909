<template>
    <div id="geofencesPanel" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="geofences"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="geofence_geofencesListSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="geofences" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="la la-pencil kt-font-brand"></i></div>
                                    <div class="alert-text">
                                        <span>{{ $t("geofence_helpText1") }}</span>
                                        <br />
                                        <span>{{ $t("geofence_helpText2") }}</span>
                                        <br />
                                        <span>{{ $t("geofence_helpText3") }}</span>
                                    </div>
                                    <div class="alert-close">
                                        <button type="button" @click="forceResize()" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="la la-close"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div id="rowGeofencesList" class="col-lg-5 col-xl-4">
                                    <!--begin::Portlet-->
                                    <div id="blocGeofencesList" class="kt-portlet kt-portlet--height-fluid" style="pointer-events: initial;">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("geofence_geofencesList") }}
                                                </h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <template v-if="(currentGeofence.state === '' || currentGeofence.state === 'HIGHLIGHT') && siteGeofences && siteGeofences.length !== 0">
                                                    <button id="createNewGeofenceButton" @click="onCreate" class="btn btn-brand btn-bold btn-sm btn-icon-h">
                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                        <span id="createNewGeofenceLabel">{{ $t("common_create") }}</span>
                                                    </button>
                                                </template>
                                                <template v-if="(currentGeofence.state === 'START_CREATION' || currentGeofence.state === 'FINALIZE_CREATION' || currentGeofence.state === 'START_MEASURE') && siteGeofences.length !== 0">
                                                    <button id="cancelCreateNewGeofenceButton" @click="onCancelCreation" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="geofenceListLink btn btn-sm btn-outline-dark btn-bold">
                                                        <span id="cancelCreateGeofenceLabel">{{ $t("common_cancel") }}</span>
                                                    </button>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span v-if="siteGeofences && siteGeofences.length == 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteGeofences.length }} {{ $t("geofence_geofence") }} &nbsp;/&nbsp; {{ totalArea }} m<sup>2</sup></span>
                                            <span v-else-if="siteGeofences && siteGeofences.length > 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteGeofences.length }} {{ $t("geofence_geofences") }} &nbsp;/&nbsp; {{ totalArea }} m<sup>2</sup></span>

                                            <!--begin emptyList component-->
                                            <app-emptylist targetObjectName="GEOFENCE" mainTextKey="geofence_notFound" subTextKey="geofence_notFoundSubLabel" imgName="empty_geofence.svg" v-bind:isDisplayed="isEmptyList" :class="{ fullWidth: true }" @oncreategeofence="onCreate" @oncancelcreategeofence="onCancelCreation"></app-emptylist>
                                            <!--end emptyList component-->

                                            <div v-if="siteGeofences && siteGeofences.length !== 0" class="form-group">
                                                <div class="kt-input-icon kt-input-icon--left" style="margin-top: -10px; margin-bottom: -10px;">
                                                    <input v-model="searchTextValue" type="search" class="form-control" :placeholder="$t('geofence_searchBy')" id="geofences_searchInput" />
                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span><i class="la la-search"></i></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active">
                                                    <div id="geofencesListWithScrollbar" class="kt-scroll" data-scroll="true" data-height="600" v-if="filteredGeofences.length > 0">
                                                        <div class="kt-widget4" id="geofencesList">
                                                            <!-- Items will goes there -->
                                                            <div v-for="(geofence, index) in filteredGeofences" :key="geofence.id" class="kt-widget4__item geofenceLine" :class="index === filteredGeofences.length - 1 ? 'kt-margin-b-30' : ''">
                                                                <div @click="onHighlight" :data-geofenceid="geofence.id" v-bind:class="getHighlightListClass(geofence)" class="kt-widget4__info">
                                                                    <a @click="onHighlight" :data-geofenceid="geofence.id" href="#" class="highlightGeofenceLocationLink kt-widget4__username" v-bind:class="getHighlightClass(geofence)">
                                                                        {{ geofence.name }}
                                                                    </a>
                                                                    <p class="kt-widget4__text colorSubText">
                                                                        {{ geofence.area }}
                                                                        <span v-if="geofence.areaUnit === 'squareMeters'"> m<sup>2</sup></span>
                                                                        <span v-else> ft<sup>2</sup></span>
                                                                    </p>
                                                                    <p class="kt-widget4__text colorSubText">{{ geofence.building.name }} / {{ geofence.floor.name }} ({{ $t("b&f_level") }}: {{ geofence.floor.floorNumber }})</p>
                                                                </div>

                                                                <template v-if="currentGeofence.id === geofence.id && currentGeofence.state == 'EDIT'">
                                                                    <button @click="onCancelEditGeofence" :data-geofenceid="geofence.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="cancelEditGeofenceLocationButton geofenceListLink btn btn-sm btn-outline-dark btn-bold"> {{ $t("common_cancel") }} </button>
                                                                    <button @click="onSaveEditGeofence" :data-geofenceid="geofence.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('geofence_saveEditGeofence')" class="saveEditGeofenceLocationButton btn btn-sm btn-outline-dark btn-bold"> <i class="la la-save editedAreaIcon"></i> {{ $t("common_save") }} </button>
                                                                </template>

                                                                <template v-if="(currentGeofence.id === '' || currentGeofence.state === 'HIGHLIGHT') && currentGeofence.state !== 'START_CREATION' && currentGeofence.state !== 'START_MEASURE'">
                                                                    <button @click="onHighlight" :data-geofenceid="geofence.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('geofence_highlightGeofence')" class="highlightGeofenceLocationButton geofenceListLink btn btn-sm btn-outline-success btn-bold">
                                                                        <i class="la la-crosshairs geofenceListIcon"></i>
                                                                    </button>
                                                                    <div
                                                                        class="editGeofenceDropdown btn-
                                                                    group dropleft show"
                                                                        data-toggle="kt-tooltip"
                                                                        :data-original-title="$t('common_edit')"
                                                                        data-placement="top"
                                                                    >
                                                                        <button class="editGeofenceDropdownButton geofenceListLink btn btn-sm btn-outline-warning btn-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                            <i class="la la-pencil geofenceListIcon"></i>
                                                                        </button>
                                                                        <!--<div class="dropdown-menu" x-placement="left-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-184px, 0px, 0px);">-->
                                                                        <div class="dropdown-menu">
                                                                            <button @click="onEditGeofenceInfos" :data-geofenceid="geofence.id" class="editGeofenceInfosItem dropdown-item">{{ $t("geofence_editInformations") }}</button>
                                                                            <button @click="onEditGeofence" :data-geofenceid="geofence.id" class="editGeofencePolygonItem dropdown-item">{{ $t("geofence_editPolygon") }}</button>
                                                                        </div>
                                                                    </div>
                                                                    <button @click="onDeleteGeofence" :data-geofenceid="geofence.id" :data-geofencename="geofence.name" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('geofence_removeGeofence')" class="removeGeofenceButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove geofenceListIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <div class="col-lg-7 col-xl-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <!--begin: map -->
                                                <app-locationmap mode="geofencesDefinition"></app-locationmap>
                                                <!--end: map -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <!-- end:: Content -->
                            </div>
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
        <app-geofencemodal></app-geofencemodal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import GeofenceModal from "../modals/geofencemodal.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import L from "leaflet"; // eslint-disable-line no-unused-vars

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteGeofencesTable: null,
            isEmptyList: false,
            isCreation: true,
            localGeofences: [],
            searchTextValue: "",
            totalArea: 0,
            isMounted: false
        };
    },
    created: function() {
        console.log("Component(Geofences)::created() - called");

        this.getSiteIndoorAreas(this.siteId);
        this.getSiteWalls(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
    },
    mounted: function() {
        console.log("Component(Geofences)::mounted() - Init metronic layout");
        this.isMounted = true;
        $(window).on("resize", () => {
            this.updateScrollbar();
        });
        this.updateScrollbar();
        this.getWallTypes();
    },
    destroyed: function() {
        console.log("Component(Geofences)::destroyed() - called");
        this.resetGeofencesState();
        this.setCurrentGeofence({ id: "", state: "" });
        this.localGeofences = [];
        this.searchTextValue = "";
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        siteGeofences(areas) {
            console.log("Component(Geofences)::watch(siteGeofences) - called with : ", areas);
            if (this.siteGeofences) {
                this.localGeofences = this.siteGeofences;
                if (this.siteGeofences.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                    this.calculateTotalArea();
                }
            }
        },

        buildingsAndTheirFloorsArray: function(arrayBF) {
            console.log("Component(Geofences)::watch(buildingsAndTheirFloorsArray) - called with : ", arrayBF);
            this.getSiteGeofences(this.siteId);
        },

        currentGeofence() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentGeofence.state === "HIGHLIGHT" && this.currentGeofence.isHighlightFromMap) {
                var element = $(".highlightGeofenceLocationLink[data-geofenceid='" + this.currentGeofence.id + "']");
                $("#geofencesListWithScrollbar").scrollTop(0);
                $("#geofencesListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteGeofences", "buildingsAndTheirFloorsArray", "getBuildingById", "getFloorByBuildingIdAndFloorId", "currentGeofence", "getGeofenceById"]),

        // Computed property used to filter
        filteredGeofences: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            var self = this;
            if (!self.searchTextValue) {
                return this.localGeofences;
            } else {
                let filtered = this.localGeofences.filter(function(geofence) {
                    return geofence.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (geofence.building && geofence.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (geofence.floor && geofence.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
                return filtered;
            }
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "setCurrentGeofence",
            "getSiteGeofences",
            "showDeleteModal",
            "resetGeofencesState",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed",
            "getSiteIndoorAreas",
            "getSiteWalls",
            "getSiteBLEGateways",
            "getWallTypes",
            "getSiteAutoCalibrationTags"
        ]),


        updateScrollbar() {
            let geofencesListScrollbar = $("#geofencesListWithScrollbar");
            let offset = 185;
            if (geofencesListScrollbar) {
                // timeout is used to be sure to get the current map height
                setTimeout(() => {
                    let mapHeight = $("#map").height();
                    geofencesListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                    geofencesListScrollbar.attr("data-height", $("#map").height() - offset);
                    KTApp.init();
                    KTLayout.init();
                }, 100);
            }
        },

        // This method is called to force a resize of the window when user click to close help top alert
        forceResize() {
            setTimeout(function() {
                window.dispatchEvent(new Event("resize"));
            }, 50);
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(geofence) {
            return {
                cursorDefault: !geofence.polygon || !geofence.polygon.type,
                highlightedName: this.currentGeofence.id === geofence.id,
                colorMainText: this.currentGeofence.id !== geofence.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(geofence) {
            return {
                cursorPointer: geofence.polygon && geofence.polygon.type,
                highlightedItemList: this.currentGeofence.id === geofence.id
            };
        },

        onCreate() {
            // small timer is needed for autoscroll over map to work correctly
            setTimeout(() => {
                // Scroll on leaflet map
                if ($("#map").offset().top > $(window).height()) {
                    // only if map is not currently visible on screen, autoscroll
                    document.getElementById("map").scrollIntoView();
                }

                this.setCurrentGeofence({ id: "", state: "START_CREATION" });
            }, 1);
        },

        onCancelCreation() {
            console.log("Component(Geofences)::onCancelCreate - Cancel button clicked.");
            if (this.currentGeofence.state === "START_MEASURE") {
                this.setCurrentGeofence({ id: "", state: "CANCEL_MEASURE" });
            } else {
                this.setCurrentGeofence({ id: "", state: "CANCEL_CREATION" });
            }
        },

        onHighlight(event) {
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            console.log("Component(Geofences)::onHighlight - Highlight button clicked. geofenceId = " + geofenceId);
            this.setCurrentGeofence({ id: geofenceId, state: "HIGHLIGHT" });
        },

        onEditGeofence(event) {
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            console.log("Component(Geofences)::onEditGeofence - Edit polygon clicked. geofenceId = " + geofenceId);
            this.setCurrentGeofence({ id: geofenceId, state: "EDIT" });
        },

        onEditGeofenceInfos(event) {
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            console.log("Component(Geofences)::onEditGeofenceInfos - Edit infos clicked. geofenceId = " + geofenceId);

            // Get last version of geofence
            let geofence = this.getGeofenceById(geofenceId);

            var geofenceData = {
                id: geofence.id,
                name: geofence.name,
                color: geofence.color,
                siteId: geofence.site,
                buildingId: geofence.building.id,
                floorId: geofence.floor.id,
                polygon: geofence.polygon,
                area: geofence.area,
                areaUnit: geofence.areaUnit,
                state: "FINALIZE_EDITION"
            };
            // Will open confirmation pop-up from geofenceModal.vue component
            this.setCurrentGeofence(geofenceData);
        },

        // This method is called when user clicks on "Cancel" button after edit one geofence
        onCancelEditGeofence(event) {
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            console.log("Component(Geofences)::onCancelEditGeofence - geofenceId = " + geofenceId);
            this.setCurrentGeofence({ id: geofenceId, state: "CANCEL_EDIT" });
        },

        // This method is called when user click ton "Save" button after edit location of one gateway
        onSaveEditGeofence(event) {
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            console.log("Component(Geofences)::onSaveEditGeofence - geofenceId = " + geofenceId);
            this.setCurrentGeofence({ id: geofenceId, state: "SAVE_EDIT" });
        },

        onDeleteGeofence(event) {
            console.log("Component(Geofences)::onDeleteGeofence - Delete button clicked. geofenceId = " + geofenceId);
            let target = event.currentTarget;
            let geofenceId = $(target).attr("data-geofenceid");
            let geofenceName = $(target).attr("data-geofencename");
            this.showDeleteModal({ textContent: i18n.t("geofence_deleteGeofenceConfirmText", { geofenceName: geofenceName }), actionName: "DELETE_GEOFENCE", params: { siteId: this.siteId, geofenceId: geofenceId } });
        },

        calculateTotalArea() {
            console.log("Component(Geofences)::calculateTotalArea - called ");
            let totalArea = 0;
            for (var geofence of this.siteGeofences) {
                totalArea += geofence.area;
            }
            this.totalArea = Math.round(totalArea * 1000) / 1000;
        },

        onNeedHelp() {
            this.$refs.geofenceDemoTour.startDemoTour();
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-geofencemodal": GeofenceModal,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.geofenceListIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.geofenceListLink {
    margin-right: 3px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}
.editedAreaIcon {
    margin-top: -4px;
}
.colorMainText {
    color: #5c7cf7 !important;
}
.colorSubText {
    color: #5a6077 !important;
}
.cursorPointer {
    cursor: pointer;
}
.font500 {
    font-weight: 500;
}
.list-top-badge {
    margin-top: -10px;
}
.counterTopList {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
    background: #009688 !important;
}
.geofenceLine a:last-child {
    margin-right: 8px;
}

.helpButtonContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<style>
.leaflet-tooltip-left:before {
    display: none;
}
</style>
