import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteRoles: [],
        rolesBySiteId: {},
        currentRole: "",
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for usersOfSite
     */

    siteRoles(state) {
        return state.siteRoles;
    },

    rolesBySiteId(state) {
        return state.rolesBySiteId;
    },

    currentRole(state) {
        return state.currentRole;
    },

    createAnotherRole(state) {
        return state.createAnother;
    },

    siteRolesByName(state) {
        let mapRoleNameToRole = {};
        for (let i = 0; i < state.siteRoles.length; i++) {
            let roleName = state.siteRoles[i].name;
            mapRoleNameToRole[roleName] = state.siteRoles[i];
        }
        return mapRoleNameToRole;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_CURRENT_ROLE(state, roleData) {
        // 1. Store current role
        state.currentRole = roleData.role;
        // 2. Update roles array
        // Find role index
        let index = _.findIndex(state.siteRoles, { id: roleData.role.id });
        if (index >= 0) {
            // Replace the role in the array of roles
            state.siteRoles.splice(index, 1, roleData.role);
        }
    },

    SET_SITE_ROLES(state, data) {
        state.siteRoles = data.roles;
    },

    SET_ROLES_BY_SITE(state, data) {
        state.rolesBySiteId = {};
        if (data.siteUsers) {
            data.siteUsers.forEach(obj => {
                if (obj.role && obj.site && obj.site.id === obj.role.site) {
                    state.rolesBySiteId[obj.site.id] = obj.role.name;
                }
            })
        }
    },

    SET_CREATE_ANOTHER_ROLE(state, createAnother) {
        state.createAnother = createAnother;
    },

    REMOVE_ROLE_BY_ID(state, roleId) {
        // Find role index
        let index = _.findIndex(state.siteRoles, { id: roleId });
        state.siteRoles.splice(index, 1);
    },

    RESET_DEFAULT_SITEROLES_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherRole({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_ROLE", createAnother);
    },

    /*
     * Action used to get the list of roles associated to a site
     */
    getSiteRoles({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/roles", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_ROLES", {
                    roles: res.data.data
                });
            })
            .catch(error => console.log(error));
    },


    /*
     * Action used to get the list of roles of logged user
     */
    getUserRolesAndSites({ commit }, data={}) {
        const tokenItem = localStorage.getItem("token");
        if (!tokenItem) {
            return;
        }
        let urlParam = '';
        if (data.hasOwnProperty("includeImage")){
            urlParam = '?includeImage=' + data.includeImage;
        }
        axiosBackend
            .get("/user/roles" + urlParam, {
                headers: { Authorization: "Bearer " + tokenItem }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_ROLES_BY_SITE", {
                    siteUsers: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get a site role from a roleId
     */
    getRoleById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/roles/" + payload.roleId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Update application in store
                commit("SET_CURRENT_ROLE", {
                    role: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create a role
     */
    createRole({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_ROLE", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/roles",
                {
                    name: payload.name,
                    features: payload.features
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_newRoleSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to buildings list page
                router.push({ name: "roles", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing role
     */
    updateRole({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/roles/" + payload.roleId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set role Data in VueX Auth store
                commit("SET_CURRENT_ROLE", {
                    role: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_roleSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to roles page
                router.push({ name: "roles", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete an role
     */
    deleteRole({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/roles/" + payload.roleId, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(() => {
                commit("REMOVE_ROLE_BY_ID", payload.roleId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_roleSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteRolesState ({ commit }) {
        commit('RESET_DEFAULT_SITEROLES_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
