<template>
    <div id="siteGatewaysHealth" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="siteGatewaysHealth"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="troubleshooting_gatewaysHealthListSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <rect
                                                                x="0"
                                                                y="0"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <path
                                                                d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                            />
                                                            <path
                                                                d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z"
                                                                id="Oval-21"
                                                                fill="#000000"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("troubleshooting_gatewaysHealthList") }}</h3>
                                            </div>
                                            <div v-if="siteGatewaysHealth && siteGatewaysHealth.length > 0" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input
                                                                        type="text"
                                                                        :placeholder="$t('common_searchByNameOrMacAddress')"
                                                                        id="gateways_searchInput"  @keyup="onSearch" v-model="searchedValue"
                                                                        class="searchInputDatatable form-control form-control-sm width270"
                                                                    />
                                                                    <span
                                                                        class="kt-input-icon__icon kt-input-icon__icon--left"
                                                                    >
                                                                        <span>
                                                                            <i class="la la-search"></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="refreshGatewaysHealthTable" id="troubleshooting_refreshGatewaysHealthTable" class="exportXLSXButton btn btn-sm btn-outline-brand kt-margin-l-10">
                                                    <i class="flaticon-refresh"></i>
                                                    {{ $t("common_refreshTable") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="GATEWAYSHEALTH_LIST" mainTextKey="troubleshooting_emptyGatewaysHealth" subTextKey="troubleshooting_emptyGatewaysHealthSubLabel" imgName="empty_gateway.svg" v-bind:isDisplayed="!siteGatewaysHealth || siteGatewaysHealth.length == 0"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="gatewaysHealth_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="siteGatewaysHealth && siteGatewaysHealth.length > 0" class="row">
                                <div class="col-xl-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <app-locationmap mode="gatewaysLocation" ref="locationmap"></app-locationmap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Portlet-->
                            </div>

                            <!-- end:: Content -->
                        </div>

                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import LocationMap from "./../map/locationmap.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteGatewaysHealthTable: null,
            siteGatewaysHealthData: null,
            searchedValue: this.$route.params.search || ""
        };
    },
    created: function() {
        console.log("Component(siteGatewaysHealth)::created() - called");
        // Load user sites from backend and store the result in sites store.
        const payload = {
            siteId: this.siteId
        }
        this.getSiteBuildingsAndTheirFloors(payload);
        this.getSiteIndoorAreas(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteGeofences(this.siteId);
        this.getSiteWalls(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
    },
    mounted: function() {
        console.log("Component(siteGatewaysHealth)::mounted() - Init metronic layout");
        this.getWallTypes();
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(siteGatewaysHealth)::destroyed() - called");
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    beforeRouteLeave(to, from, next) {
        this.resetSiteHealthState();
        next();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteBuildings(buildingsArray) {
            this.getSiteGatewaysHealth(this.siteId);
        },
        siteGatewaysHealth: function() {
            console.log("Component(siteGatewaysHealth)::watch(siteGatewaysHealth) called");
            this.siteGatewaysHealthData = this.buildDatatableData();
            this.initKtDataTable(this.siteGatewaysHealthData);
            this.getSiteBLEGateways(this.siteId);

            if(this.searchedValue){
                setTimeout(() => {
                    this.onSearch();
                }, 200)
            }
        },
        user: function(user) {
            if (user) {
                console.log("Component(siteGatewaysHealth)::watch(user) called with :", user);
                this.siteGatewaysHealthData = this.buildDatatableData();
                this.initKtDataTable(this.siteGatewaysHealthData);
            }
        }
    },
    computed: {
        ...mapGetters(["siteGatewaysHealth", "user", "siteBuildings", "siteBLEGateways"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteGatewaysHealth",
            "getSiteBuildingsAndTheirFloors",
            "getSiteBLEGateways",
            "resetSiteHealthState",
            "getSiteGeofences",
            "getSiteBLEGateways",
            "getSiteIndoorAreas",
            "getSiteWalls",
            "getWallTypes",
            "getSiteAutoCalibrationTags",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed"
        ]),

        buildDatatableData() {
            let data = [];
            if (this.siteGatewaysHealth) {
                data = this.siteGatewaysHealth.map((gwh) => {
                    let finalGatewayHealth = {...gwh};
                    if (gwh.lastEmission && gwh.lastEmission != -1) {
                        finalGatewayHealth.status = i18n.t("troubleshooting_gatewayStatusUp");
                    } else {
                        finalGatewayHealth.status = i18n.t("troubleshooting_gatewayStatusDown");
                    }
                    if (gwh.hasOwnProperty('lastKonLocReceived')) {
                        if (gwh.lastKonLocReceived) {
                            finalGatewayHealth.lastKonLocReceivedStatus = "far fa-check-circle text-success";
                        } else {
                            finalGatewayHealth.lastKonLocReceivedStatus = "fas fa-exclamation-circle text-danger";
                        }
                    } else {
                        finalGatewayHealth.lastKonLocReceivedStatus = "N/A";
                    }
                    if (gwh.hasOwnProperty('lastKonSPReceived')) {
                        if (gwh.lastKonSPReceived) {
                            finalGatewayHealth.lastKonSPReceivedStatus = "far fa-check-circle text-success";
                        } else {
                            finalGatewayHealth.lastKonSPReceivedStatus = "fas fa-exclamation-circle text-danger";
                        }
                    } else {
                        finalGatewayHealth.lastKonSPReceivedStatus = "N/A";
                    }
                    if (gwh.hasOwnProperty('uploadInterval')) {
                        if (gwh.uploadInterval && gwh.uploadInterval != -1) {
                            finalGatewayHealth.uploadIntervalValue = gwh.uploadInterval + "s";
                        } else {
                            finalGatewayHealth.uploadIntervalValue = "N/A";
                        }
                    } else {
                        finalGatewayHealth.uploadIntervalValue = "N/A";
                    }
                    return finalGatewayHealth;
                });
            }
            return data;
        },

        initKtDataTable(data, pageSize) {

            if (this.siteGatewaysHealth != null) {

                // If site table has been already build, destroy the table before creating a fresh instance !
                if (this.siteGatewaysHealthTable) {
                    $("#gatewaysHealth_datatable").KTDatatable().destroy();
                    // After destroy set to null
                    this.siteGatewaysHealthTable = null;
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.siteGatewaysHealth.length > 0) {

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(data),
                            pageSize: (pageSize ? pageSize : 20),
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: true,
                        pagination: true,
                        columns: [
                            {
                                field: "name",
                                title: i18n.t("troubleshooting_gatewayNameLabel"),
                                textAlign: "center",
                                overflow: "visible",
                                autoHide: false,
                                template: (row) => {
                                    if (this.user.isTSUser){
                                        return row.name;
                                    } else {
                                        return (
                                            '\
                                            <div class="kt-user-card-v2">\
                                                <div class="kt-user-card-v2__details openResourceLink">\
                                                    <a class="editBLEGatewayButton kt-link kt-user-card-v2__name" data-gatewayid="' +
                                            row.id +
                                            '" href="javascript:;">' +
                                            row.name +
                                            "</a>\
                                                </div>\
                                            </div>\
                                        "
                                        );
                                    }
                                }
                            },
                            {
                                field: "bleMacAddress",
                                title: i18n.t("troubleshooting_gatewayMacAddressLabel"),
                                textAlign: "center"
                            },
                            {
                                field: "status",
                                title: i18n.t("troubleshooting_gatewayStatus"),
                                textAlign: "center",
                                template: function(row) {
                                    if (row.lastEmission && row.lastEmission != -1) {
                                        return (
                                            '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                            row.status +
                                            "</span>"
                                        );
                                    } else {
                                        return (
                                            '<span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' +
                                            row.status +
                                            "</span>"
                                        );
                                    }
                                }
                            },
                            {
                                field: "lastKonLocReceivedStatus",
                                width: 100,
                                title: i18n.t("troubleshooting_locationFrameLabel"),
                                textAlign: "center",
                                template: function(row) {
                                    if (row.hasOwnProperty('lastKonLocReceived')) {
                                        return '<i class="' + row.lastKonLocReceivedStatus + '" style=" font-size: 20px; "></i>';
                                    } else {
                                        return row.lastKonLocReceivedStatus;
                                    }
                                }
                            },
                            {
                                field: "lastKonSPReceivedStatus",
                                width: 100,
                                title: i18n.t("troubleshooting_secureProfileFrameLabel"),
                                textAlign: "center",
                                template: function(row) {
                                    if (row.hasOwnProperty('lastKonSPReceived')) {
                                        return row.gatewayType === "STELLAR" ? '<i class="' + row.lastKonSPReceivedStatus + '" style=" font-size: 20px; "></i>' : "N/A";
                                    } else {
                                        return row.lastKonSPReceivedStatus;
                                    }
                                }
                            },
                            {
                                field: "uploadIntervalValue",
                                width: 100,
                                title: i18n.t("troubleshooting_secureUploadIntervalLabel"),
                                textAlign: "center"
                            },
                            {
                                field: "lastEmission",
                                title: i18n.t("troubleshooting_gatewayLastEmissionLabel"),
                                sortable: "desc",
                                overflow: "visible",
                                autoHide: false,
                                width: 150,
                                textAlign: "center",
                                template: function(row) {
                                    if (row.lastEmission && row.lastEmission != -1) {
                                        return (
                                            '<div>' +
                                            moment(row.lastEmission).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale())) +
                                            '</div><div align="center">(' + moment(row.lastEmission).fromNow() +')</div>'
                                        );
                                    } else {
                                        return "N/A";
                                    }
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.siteGatewaysHealthTable = $("#gatewaysHealth_datatable").KTDatatable(options);
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnSiteGatewaysHealthTable();

                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.siteGatewaysHealthTable = null;
                }
            }
        },

        registerEventsOnSiteGatewaysHealthTable() {
            var self = this;
            $("#gatewaysHealth_datatable").on(
                "kt-datatable--on-layout-updated",
                () => {
                    $(".editBLEGatewayButton")
                        .off()
                        .on("click", function() {
                            let gatewayId = $(this).attr("data-gatewayid");
                            console.log(
                                "Component(siteGatewaysHealth)::registerEventsOnSiteGatewaysHealthTable(clickOnGatewayName) clicked. GatewayId = " +
                                    gatewayId
                            );
                            // Hide and destroy element’s tooltip
                            $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                            // Go to edit vue
                            self.onEditBLEGateway(gatewayId);
                        });
                    // Force width pager size
                    setTimeout(() => {
                        $(".kt-datatable__pager-size").css("width", "80px");

                        // Init tooltips
                        $('[data-toggle="kt-tooltip"]').tooltip({
                            trigger: "hover"
                        });
                    }, 50);
                }
            );
        },

        onEditBLEGateway: function(gatewayId) {
            this.$router.push({
                name: "editGateway",
                params: { gatewayId: gatewayId, siteId: this.siteId }
            });
        },

        refreshGatewaysHealthTable: function() {
            // Load user sites from backend and store the result in sites store.
            this.getSiteGatewaysHealth(this.siteId);

        },

        onSearch: function() {
            if (this.siteGatewaysHealthTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // To search EITHER by name OR by macAddress:
                // 1. Search in "name" field of gateways health data
                const dataFoundByName = _.filter(this.siteGatewaysHealthData, gwh => gwh && gwh.name && gwh.name.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "bleMacAddress" field of gateways health data
                const dataFoundByMacAddress = _.filter(this.siteGatewaysHealthData, gwh => gwh && gwh.bleMacAddress && gwh.bleMacAddress.toUpperCase().includes(searchedValueUpperCase));
                // 3. Create an array with previous unique resulting searched
                const dataFound = _.unionBy(dataFoundByName, dataFoundByMacAddress, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.siteGatewaysHealthTable.getPageSize());
                } else {
                    // No searchedValue found => Display empty datatable
                    this.siteGatewaysHealthTable.search(this.searchedValue, "unknown");
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
