import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteBuildings: null,
        currentBuilding: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    siteBuildings(state) {
        return state.siteBuildings;
    },

    currentBuilding(state) {
        return state.currentBuilding;
    },

    getBuildingInSiteBuildingsById: (state) => (buildingId) => {
        if (state.siteBuildings){
            for (let i = 0; i < state.siteBuildings.length; i++) {
                if (state.siteBuildings[i].id == buildingId){
                    return state.siteBuildings[i];
                }
            }
        }
        return null;
    },

    getFloorInSiteBuildingsById: (state) => (floorId) => {
        if (state.siteBuildings){
            for (let i = 0; i < state.siteBuildings.length; i++) {
                if (state.siteBuildings[i].floors && state.siteBuildings[i].floors.length > 0){
                    for (let j = 0; j < state.siteBuildings[i].floors.length; j++) {
                        if (state.siteBuildings[i].floors[j].id == floorId){
                            return state.siteBuildings[i].floors[j];
                        }
                    }
                }
            }
        }
        return null;
    },

    totalNumberOfFloors(state) {
        let totalNumberOfFloors = null;
        if (state.siteBuildings !== null) {
            //totalNumberOfFloors = 0;
            if (state.siteBuildings.length > 0) {
                for (let i = 0; i < state.siteBuildings.length; i++) {
                    if (state.siteBuildings[i].floors) {
                        if (totalNumberOfFloors === null) {
                            totalNumberOfFloors = 0;
                        }
                        totalNumberOfFloors += state.siteBuildings[i].floors.length;
                    }
                }
            } else {
                totalNumberOfFloors = 0;
            }
        }
        return totalNumberOfFloors;
    },

    createAnotherBuilding(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_BUILDINGS(state, data) {
        state.siteBuildings = data.buildings;
    },

    REMOVE_BUILDING_BY_ID(state, buildingId) {
        // Find building index
        let index = _.findIndex(state.siteBuildings, { id: buildingId });
        state.siteBuildings.splice(index, 1);
    },

    SET_CURRENT_BUILDING(state, buildingData) {
        if (!state.siteBuildings) {
            state.siteBuildings = [];
        }
        // Set the current building
        state.currentBuilding = buildingData.currentBuilding;
        // Find building index
        let index = _.findIndex(state.siteBuildings, { id: buildingData.currentBuilding.id });
        if (index >= 0) {
            // Replace the building in the array of buildings
            state.siteBuildings.splice(index, 1, buildingData.currentBuilding);
        } else {
            state.siteBuildings.push(buildingData.currentBuilding);
        }
    },

    SET_CREATE_ANOTHER_BUILDING(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_BUILDINGS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherBuilding({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_BUILDING", createAnother);
    },

    /*
     * Action used to create a building
     */
    createBuilding({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_BUILDING", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/buildings",
                {
                    name: payload.name,
                    description: payload.description
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_newBuildingSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to buildings list page
                router.push({ name: "buildings", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site buildings
     */
    getSiteBuildings({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/buildings", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store site buildings in userBuildings
                commit("SET_SITE_BUILDINGS", {
                    buildings: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site buildings and their floors
     */
    getSiteBuildingsAndTheirFloors({ commit }, payload) {
        const siteId = payload.siteId;
        let urlParam = '';
        if (payload.hasOwnProperty("includeImage")){
            urlParam = '?includeImage=' + payload.includeImage;
        }
        axiosBackend
            .get("/sites/" + siteId + "/buildings/floors" + urlParam, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store site buildings in userBuildings
                commit("SET_SITE_BUILDINGS", {
                    buildings: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one building by its id
     */
    getBuildingById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/buildings/" + payload.buildingId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_BUILDING", {
                    currentBuilding: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing building
     */
    updateBuilding({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_BUILDING", {
                    currentBuilding: res.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_buildingSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to buildings page
                router.push({ name: "buildings", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a building
     */
    deleteBuilding({ commit, dispatch }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/buildings/" + data.buildingId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_BUILDING_BY_ID", data.buildingId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_buildingSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetBuildingsState ({ commit }) {
        commit('RESET_DEFAULT_BUILDINGS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
